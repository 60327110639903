import Skeleton from "react-loading-skeleton";

export const NewMoneyTableRow = ({ columns, data, index, style, isClearing }) => {
    const { height } = style;

    // do not return a data row when the data has not yet been collected from the backend
    if (isClearing || (index >= data.length)) 
        return <tr>
            <td style={{ height }} colSpan={columns.length}>
                <Skeleton height={32} />
            </td>
        </tr>
    
    return <tr index={index}>
        {columns.map((col, colIndex) => col.CellComponent
            ? <col.CellComponent key={`row-col-${colIndex}`} value={data[index]?.[col.property]} index={index} row={data[index]} />
            : <td key={`row-col-${colIndex}`} className={`align-middle ${col.cellClassName ?? ""}`}>
                {col.cellFormatter
                    ? col.cellFormatter(data[index]?.[col.property])
                    : data[index]?.[col.property]}
            </td>)}
    </tr>
};