import { applyPatch } from "fast-json-patch";
import { patchReplace } from "../../helpers/patchDoc";
import { wealthProApi } from "../../redux/api";

const userMessagesApiEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        createMessageForUser: builder.mutation({
            query: ({ userId, ...request }) => ({
                url: `api/messaging/users/${userId}/messages`,
                method: 'POST',
                body: {
                    statusText: "",
                    isDone: false,
                    message: "",
                    threadSubject: "",
                    recipientIds: [],
                    ...request,
                },
            }),
            async onQueryStarted({ userId, threadId }, { dispatch, queryFulfilled }) {
                try {

                    const { data: message } = await queryFulfilled;
                    dispatch(wealthProApi.util.updateQueryData('fetchThreadMessagesForUser', { userId, threadId }, ({ results, totalCount, ...rest }) => {
                        return {
                            ...rest,
                            results: [{
                                ...message,
                                recipients: message.recipientIds.map(userId => ({ userId }))
                            }, ...results],
                            totalCount: totalCount + 1
                        }
                    }));

                    dispatch(wealthProApi.util.updateQueryData('searchUserMessages', { userId }, ({ results, pagination }) => {
                        return {
                            results: [message, ...results],
                            pagination: {
                                ...pagination,
                                totalCount: pagination.totalCount + 1
                            }
                        }
                    }));
                }
                catch { }
            }
        }),
        createMessageWithAttachmentsForUser: builder.mutation({
            query: ({ userId, request }) => ({
                url: `api/messaging/users/${userId}/messages/withAttachments`,
                method: 'POST',
                body: request,
            }),
            async onQueryStarted({ userId, threadId }, { dispatch, queryFulfilled }) {
                try {
                    const { data: message } = await queryFulfilled;

                    dispatch(wealthProApi.util.updateQueryData('fetchThreadMessagesForUser', { userId, threadId }, ({ results, totalCount, ...rest }) => {
                        return {
                            ...rest,
                            results: [{
                                ...message,
                                recipients: message.recipientIds.map(userId => ({ userId }))
                            }, ...results],
                            totalCount: totalCount + 1
                        }
                    }));

                    dispatch(wealthProApi.util.updateQueryData('searchUserMessages', { userId }, ({ results, ...rest }) => {
                        return {
                            results: [message, ...results],
                            ...rest
                        }
                    }));

                }
                catch { }
            }
        }),
        uploadMessageAttachmentsForUser: builder.mutation({
            query: ({ request }) => ({
                url: `api/messaging/messages/attachments`,
                method: 'POST',
                body: request,
            }),
            async onQueryStarted({ userId, messageId, threadId }, { dispatch, queryFulfilled }) {
                try {
                    const { data: newAttachments } = await queryFulfilled;
                    dispatch(wealthProApi.util.updateQueryData('fetchThreadMessagesForUser', { userId, threadId }, ({ results }) => {
                        let item = results.find(x => x.messageId === messageId);
                        item.attachments = [...item.attachments, ...newAttachments];
                    }));

                    dispatch(
                        wealthProApi.util.updateQueryData(
                            'getThreadById',
                            { threadId },
                            (thread) => {
                                thread.threadAttachments = [...thread.threadAttachments, ...newAttachments]
                            }
                        ));
                }
                catch { }
            }
        }),
        searchUserMessages: builder.query({
            query: ({ userId, request }) => ({
                url: `api/messaging/users/${userId}/messages/search`,
                method: 'POST',
                body: request
            }),
            serializeQueryArgs: ({ endpointName, queryArgs }) => {
                const { userId } = queryArgs || {};
                return `${endpointName}-${userId}`
            },
            merge: (currentCache, newItems) => {
                const { page: currPage } = currentCache || {};
                const { page: nextPage, hasMore } = newItems || {};

                if (!currPage || nextPage <= currPage) {
                    return newItems;
                }

                currentCache.hasMore = hasMore;
                currentCache.page = nextPage;
                currentCache.results.push(...newItems.results);
            },
            forceRefetch: ({ currentArg, previousArg }) => {
                const { page: currPage, userId: currUserId } = currentArg || { page: 1, userId: null };
                const { page: prevPage, userId: prevUserId } = previousArg || { page: 0, userId: null };
                return currPage !== prevPage || currUserId !== prevUserId;
            },
        }),
        readUserMessage: builder.mutation({
            query: ({ userId, messageId, request }) => ({
                url: `api/messaging/users/${userId}/messages/${messageId}/read`,
                method: 'POST',
                body: request
            }),
            async onQueryStarted({ messageId, userId }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    dispatch(
                        wealthProApi.util.updateQueryData(
                            'searchUserMessages',
                            { userId },
                            ({ results, ...rest }) => {
                                var message = results.find(x => x.messageId === messageId);


                                if (data.userNoFurtherActionTime) {
                                    return {
                                        ...rest,
                                        results: results.filter(x => x.messageId !== messageId)
                                    };
                                }

                                Object.assign(message, data);
                            }
                        )
                    );
                }
                catch { }
            }
        }),
        unreadUserMessage: builder.mutation({
            query: ({ userId, messageId, request }) => ({
                url: `api/messaging/users/${userId}/messages/${messageId}/unread`,
                method: 'POST',
                body: request
            }),
            async onQueryStarted({ messageId, userId }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    dispatch(
                        wealthProApi.util.updateQueryData(
                            'searchUserMessages',
                            { userId },
                            ({ results }) => {
                                var message = results.find(x => x.messageId === messageId);
                                Object.assign(message, data);
                            }
                        )
                    );
                }
                catch { }
            }
        }),
        sendUserMessage: builder.mutation({
            query: ({ userId, messageId }) => ({
                url: `api/messaging/users/${userId}/messages/${messageId}/send`,
                method: 'POST'
            }),
            async onQueryStarted({ userId, threadId, messageId }, { dispatch, queryFulfilled }) {

                try {
                    const { data } = await queryFulfilled;
                    dispatch(wealthProApi.util.updateQueryData(
                        'fetchThreadMessagesForUser',
                        { userId, threadId },
                        ({ results, totalCount, ...rest }) => {

                            var filteredResults = results.filter(x => x.messageId !== messageId);

                            return {
                                ...rest,
                                totalCount,
                                results: [data, ...filteredResults]
                            };
                        }
                    ));

                    dispatch(wealthProApi.util.updateQueryData(
                        'searchUserMessages',
                        { userId },
                        ({ results, pagination: { totalCount, ...rest } }) => {

                            var filteredResults = results.filter(x => x.messageId !== messageId);

                            return {
                                pagination: {
                                    ...rest,
                                    totalCount,
                                },
                                results: [{
                                    ...data,
                                    recipientNames: data.recipients.map(({ displayName }) => displayName),
                                    recipientIds: data.recipients.map(({ userId }) => userId),
                                    subject: data.threadSubject,
                                    userMessagePriority: null,
                                    deadlineDate: null,
                                    userReminderDate: null

                                }, ...filteredResults]
                            };
                        }
                    ));
                }
                catch { }
            },
        }),
        toggleMessageIsDoneForUser: builder.mutation({
            query: ({ userId, messageId, newValue }) => ({
                url: `api/messaging/users/${userId}/messages/${messageId}/done`,
                method: 'PATCH',
                body: {
                    isDone: newValue
                }
            }),
            async onQueryStarted({ messageId, userId, threadId }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    var operations = [
                        patchReplace("isDone", data.newValue),
                        patchReplace("doneDate", data.doneDate),
                        patchReplace("doneByName", data.toggledByName),
                        patchReplace("doneById", data.toggledBy)
                    ];

                    dispatch(
                        wealthProApi.util.updateQueryData(
                            'searchUserMessages',
                            { userId },
                            ({ results }) => {
                                var message = results.find(x => x.messageId === messageId);
                                applyPatch(message, operations, true);
                            }
                        )
                    );

                    dispatch(
                        wealthProApi.util.updateQueryData(
                            'fetchThreadMessagesForUser',
                            { userId, threadId },
                            ({ results }) => {
                                var message = results.find(x => x.messageId === messageId);
                                applyPatch(message, operations, true);
                            }
                        )
                    );
                }
                catch { }
            }
        })
    })
});

export const {
    useCreateMessageForUserMutation,
    useCreateMessageWithAttachmentsForUserMutation,
    useUploadMessageAttachmentsForUserMutation,
    useLazySearchUserMessagesQuery,
    useReadUserMessageMutation,
    useUnreadUserMessageMutation,
    useSendUserMessageMutation,
    useToggleMessageIsDoneForUserMutation
} = userMessagesApiEndpoints;