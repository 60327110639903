/**
 * Component for generated a basic Bootstrap table with all styling
 * required to make it scalable across various screen sizes.
 * 
 * NOTE: trying to make it more customisable and not having default "Date Column, etc"
 * 
 * What to implement:
 * - Footer and calculations
 * - Alignments and column options
 * - Column Renderers
 */
import _ from "lodash";
import React from "react";
import classNames from "classnames";
import styled, { css } from "styled-components";
import Skeleton from "react-loading-skeleton";
import { ErrorMessage } from "../messages";
import { variantToColour } from "../../helpers/theme";
import { darken } from "polished";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Clickable = css`
    &:hover{
        background-color: ${props => darken(props.theme.linkDarkenAmount, props.theme.light)};
        cursor: pointer;
    }
`;

export const FixedCell = styled.td`
    width: ${props => props.width}%;
    max-width: 0;
`;

export const ThemedTable = styled.table`
    margin-bottom: 0;
`;

export const ThemedTableHeader = styled.th`
    z-index: 1;
    vertical-align: middle !important;
    color: ${props => variantToColour({ theme: props.theme, variant: props.variant ? props.variant : 'primary' })};
    ${props => props.clickable && Clickable};
`;

export const StickyThemedTableHeaderRow = styled.tr`
    z-index: 1;
    position: sticky;
    top: 0;
    background-color: white !important;
`;

export const ThemedTableHeaderActionColumn = styled(ThemedTableHeader)`
    width: 32px;
`;

export const StickyThemedTableHeader = styled(ThemedTableHeader)`
    position: sticky;
    top: 0;
    background-color: white !important;
`;

export const StickyThemedTableHeaderActionColumn = styled(ThemedTableHeaderActionColumn)`
    position: sticky;
    top: 0;
    background-color: white !important;
`;

export const StandardTableClickableRow = styled.tr`
    ${Clickable};
`;

export const StandardTableFormCheckColumn = styled.td`
    width: 78px;

    && > div.row {
        justify-content: center;
    }

    && > div.row > div {
        margin: 0;
    }
`;

export const StandardTableCreatingRow = ({ columns, count = 1, height = 37, ...rest }) => {
    return <tr {...rest}>
        <td colSpan={columns.length}>
            <Skeleton count={count} height={height} />
        </td>
    </tr>
};

export const StandardTableEmptyRow = ({ columns, ...rest }) => {
    return <tr {...rest}>
        <td colSpan={columns.length}>Your search did not yield any results.</td>
    </tr>
};

export const StandardTableErrorRow = ({ columns, error, ...rest }) => {
    return <tr {...rest}>
        <td colSpan={columns.length}>
            <ErrorMessage error={error} />
        </td>
    </tr>
};

export const StandardTableLoadingRow = ({ columns, ...rest }) => {
    return (
        <React.Fragment>
            <tr {...rest}>
                <td colSpan={columns.length}>
                    <Skeleton height={30} />
                </td>
            </tr>
            <tr {...rest}>
                <td colSpan={columns.length}>
                    <Skeleton height={30} />
                </td>
            </tr>
            <tr {...rest}>
                <td colSpan={columns.length}>
                    <Skeleton height={30} />
                </td>
            </tr>
        </React.Fragment>
    )
};

export const StandardTableSortableHeader = ({ className, children, active, direction, onClick, isSticky = false }) => {
    return (
        <ThemedTableHeader clickable className={classNames(className, {
            'sticky-header': isSticky
        })} onClick={onClick}>
            {children}
            {active && <FontAwesomeIcon className="ms-3" icon={direction?.toLowerCase() === "desc" ? "arrow-down" : "arrow-up"} />}
        </ThemedTableHeader>
    );
};

export const StandardTableSortablePropertyHeader = ({ 
    children, 
    property = "", 
    className = null, 
    sort = "", 
    sortTable = () => {}, 
    isSticky = true 
}) => {
    const sortProperties = sort.split(',');
    const isHeaderSorted = _.some(sortProperties, i => i.includes(property));
    const headerDirection = isHeaderSorted && _.some(sortProperties, i => i.toLowerCase().includes('+desc')) ? "desc" : null;

    const handleClick = () => sortTable(property);
    
    return (
        <StandardTableSortableHeader 
            className={classNames(className)}
            active={isHeaderSorted}
            isSticky={isSticky}
            direction={headerDirection}
            onClick={handleClick}
        >
            {children}
        </StandardTableSortableHeader>
    );
};

export const StandardTableRow = ({ columns, data, ...rest }) => {
    return <tr {...rest}>
        {columns.map((col, colIndex) => <td key={`row-col-${colIndex}`}>{data[col.property]}</td>)}
    </tr>
};

const StandardTable = ({
    className = "",
    parentClassName = "",
    columns = [],
    CreatingRowComponent = StandardTableCreatingRow,
    creatingRowComponentProps = {},
    data = [],
    EmptyRowComponent = StandardTableEmptyRow,
    emptyRowComponentProps = {},
    error = null,
    ErrorRowComponent = StandardTableErrorRow,
    errorRowComponentProps = {},
    hideHeaderOnEmpty = false,
    LoadingRowComponent = StandardTableLoadingRow,
    loadingRowComponentProps = {},
    isCreating = false,
    isLoading = false,
    isTableResponsive = true,
    RowComponent = StandardTableRow,
    rowComponentProps = {},
    variant = "primary",
    onDoubleClick,
    onParentDoubleClick,
    ...rest
}) => {
    const canRenderHeader = () => {
        if ((error || data.length === 0) && hideHeaderOnEmpty === true) {
            return false;
        }
        return true;
    };

    const renderColumnHeader = (col, index) => {
        if (col.headerRenderer && typeof (col.headerRenderer) === 'function') {
            return col.headerRenderer({ ...col, variant, key: `col-${index}` })
        }
        return (
            <ThemedTableHeader key={`col-${index}`} className={col.headerClassName ? col.headerClassName : ''} variant={variant}>
                {col.label}
            </ThemedTableHeader>
        );
    };

    return <div className={classNames({ 'table-responsive': isTableResponsive }, parentClassName)} onDoubleClick={onParentDoubleClick}>
        <ThemedTable className={`table table-wp3 ${className}`} onDoubleClick={onDoubleClick}>
            {canRenderHeader() === true && (
                <thead>
                    <tr>
                        {columns.map((el, i) => renderColumnHeader(el, i))}
                    </tr>
                </thead>
            )}
            <tbody>
                {isLoading === true && <LoadingRowComponent columns={columns} {...loadingRowComponentProps} />}
                {isLoading === false && error && <ErrorRowComponent columns={columns} error={error} {...errorRowComponentProps} />}
                {isLoading === false && !error && data.length === 0 && <EmptyRowComponent columns={columns} {...emptyRowComponentProps} />}
                {isLoading === false && !error && data.length > 0 && data.map((rec, rowIndex) => <RowComponent key={`row-${rowIndex}`} columns={columns} data={rec} index={rowIndex} {...rowComponentProps} />)}
                {isLoading === false && isCreating === true && <CreatingRowComponent columns={columns} count={1} height={37} {...creatingRowComponentProps} />}
            </tbody>
        </ThemedTable>
    </div>
};

export default StandardTable;