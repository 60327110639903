import { Modal } from "react-bootstrap";
import MessageThreadProvider from "./hooks/MessageThreadContext";
import { Button } from "../../components";
import styled from "styled-components";
import ThreadMessages from "./components/ThreadMessages";
import ThreadHeader from "./components/ThreadHeader";

const ThreadModalBody = styled(Modal.Body)`
    min-height: 66.67vh;
    display: flex;
    flex-direction: column;
`;

export const MessageThreadModal = ({ handleClose, show, threadId, userId }) => {

    const onClickHide = (e) => {
        if (e && typeof (e.preventDefault) === "function") {
            e.preventDefault();
        }

        handleClose(e);
    };

    return <Modal
        className="overflow-visible"
        size="xl"
        centered
        onHide={onClickHide}
        backdrop="static"
        show={show}
    >
        <Modal.Header closeButton />
        <ThreadModalBody className="px-4">
            <MessageThread threadId={threadId} userId={userId} />
        </ThreadModalBody>
        <Modal.Footer>
            <Button variant="light" onClick={onClickHide}>Close</Button>
        </Modal.Footer>
    </Modal>;
};

const MessageThread = ({ threadId, userId }) => {
    return <MessageThreadProvider threadId={threadId} userId={userId}>
        <ThreadHeader />
        <div className="flex-grow-1">
            <ThreadMessages />
        </div>

    </MessageThreadProvider>;
};

export default MessageThread;