import _ from "lodash";
import React from "react";
import toast from "react-hot-toast";
import classNames from "classnames";
import useReportFeedback from "../hooks/useReportFeedback";
import { useReportHistory } from "../hooks/ReportHistoryContext";
import { useClientReportControls } from "../../../hooks/ClientReportControlsContext";
import { LoadingMessage } from "../../../components/loaders";
import { ThemedSpan } from "../../../components/utilities";
import { FeedbackRatingSelect, GenericUserSelect } from "../../../components/forms/selects";
import { FormCheck, FormTextArea } from "../../../components/forms";
import { ProfileDisplay } from "../../../components";

const ReportFeedbackRecord = ({ className, data, isLoading = false, update, updateRecipients, ...rest }) => {
    const { isAllowedToChangeReportFeedback } = useClientReportControls();
    const { comment, feedbackAspectDescription, id, isApplied, rating, recipients, receivedFromUserId } = data;

    return (
        <div className={classNames(className)} {...rest}>
            <div className="card">
                <div className="card-body">
                    <div className={classNames({ "mb-2": isApplied })}>
                        <FormCheck 
                            className="has-pointer"
                            label={feedbackAspectDescription}
                            isChecked={isApplied}
                            disabled={!isAllowedToChangeReportFeedback}
                            onChange={(_) => update(id, 'isApplied', !isApplied)}
                        />
                    </div>
                    <div className={classNames("row mb-2", { "d-none": !isApplied })}>
                        <div className="col-6">
                            <FeedbackRatingSelect 
                                label="Rating"
                                defaultValue={rating}
                                isDisabled={!isAllowedToChangeReportFeedback}
                                onBlur={(value) => update(id, 'rating', value.value)}
                            />
                        </div>
                        <div className="col-6">
                            <GenericUserSelect 
                                isMulti={true}
                                isDisabled={!isAllowedToChangeReportFeedback}
                                defaultValue={recipients}
                                label="Responsible Persons"
                                placeholder="Please select the Feedback Recipients."
                                onBlur={(value) => updateRecipients(id, value.map(e => e.value))}
                            />
                        </div>
                    </div>
                    <div className={classNames("mb-2", { "d-none": !isApplied })}>
                        <FormTextArea 
                            minRows={3}
                            maxRows={15}
                            label="Comments"
                            value={comment}
                            disabled={!isAllowedToChangeReportFeedback}
                            placeholder="Feedback comment..."
                            onBlur={(e) => update(id, 'comment', e)}
                        />
                    </div>
                    <div className={classNames("d-flex justify-content-end", { "d-none": !isApplied })} style={{ fontSize: '.75rem' }}>
                        <ThemedSpan variant="muted" className="fst-italic me-1">Received from:</ThemedSpan>
                        {receivedFromUserId
                            ? (<ProfileDisplay userId={receivedFromUserId} />)
                            : (<ThemedSpan variant="muted" className="fst-italic">System Generated</ThemedSpan>)
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

const ReportFeedbackList = () => {
    const { report: { masterAccountId, id } } = useReportHistory();
    const { data, isLoading, updateRecipients, updateRecipientResults, updateRecord, updateResult } = useReportFeedback(masterAccountId, id);
    const { isLoading: isUpdatingRecipients } = updateRecipientResults;
    const { isLoading: isUpdatingRecord } = updateResult;

    const _update = (feedbackId, prop, value) => {
        toast.promise(updateRecord(feedbackId, prop, value), {
            loading: 'Updating feedback...',
            success: 'Feedback has been updated.',
            error: 'There was an issue updating the Report Feedback.'
        });
    };

    const _updateRecipients = (feedbackId, values) => {
        toast.promise(updateRecipients(feedbackId, values), {
            loading: 'Updating feedback recipients...',
            success: 'Feedback Recipients have been updated.',
            error: 'There was an issue updating the Report Recipients.'
        });
    };

    if (isLoading === true || data === null) {
        return <LoadingMessage text="Please wait whilst WealthPro gets the Report Feedback history" />
    }

    if (data.length === 0) {
        return (
            <div className="d-flex flex-column flex-fill justify-content-center align-items-center my-3">
                <ThemedSpan variant="muted">There is no Feedback available for this Report.</ThemedSpan>
            </div>
        );
    }

    return (
        <React.Fragment>
            {data.map((data) => (
                <ReportFeedbackRecord 
                    key={data.id} 
                    className="px-3 mb-3" 
                    data={data}
                    update={_update}
                    updateRecipients={_updateRecipients}
                    isLoading={isUpdatingRecipients === true || isUpdatingRecord === true}
                />
            ))}
        </React.Fragment>
    );
};

export default ReportFeedbackList;