import { wealthProApi } from '../../redux/api';

const holdingsApiEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        fetchOwnedProducts: builder.query({
            query: ({ masterAccountId, wrapperIds }) => ({
                url: `api/valuations/productlist`,
                method: 'POST',
                body: {
                    masterAccountId,
                    wrapperIds
                }
            })
        }),
        fetchValuationDesignations: builder.query({
            query: ({ masterAccountId, productId }) => `api/valuations/designations/${masterAccountId}/${productId}`,
        }),
        fetchValuationProviders: builder.query({
            query: ({ masterAccountId, productId, designationId }) => designationId == null
                ? `api/valuations/holdings/providers/${masterAccountId}/product/${productId}`
                : `api/valuations/holdings/providers/${productId}/${designationId}`
        }),
        fetchAssetFromValuations: builder.query({
            query: ({ designationId, productId, providerRef, custodyRef, assetId }) => ({
                url: `api/valuations/asset`,
                method: 'POST',
                body: {
                    designationId,
                    productId,
                    providerReference: providerRef,
                    custodianReference: custodyRef,
                    assetId
                }
            })
        }),
        fetchHoldings: builder.query({
            query: ({ designationId, productId, custodianReference, providerReference }) => ({
                url: `api/valuations/holdings`,
                method: 'POST',
                body: {
                    designationId,
                    productId,
                    custodianReference,
                    providerReference
                }
            })
        })
    })
});

export const {
    useLazyFetchOwnedProductsQuery,
    useLazyFetchValuationDesignationsQuery,
    useLazyFetchValuationProvidersQuery,
    useLazyFetchAssetFromValuationsQuery,
    useLazyFetchHoldingsQuery
} = holdingsApiEndpoints;