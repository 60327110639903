import React, { useEffect, useId, useState } from "react";
import NotificationsDropdownMenu from "./components/NotificationsDropdownMenu";
import NotificationsDropdownToggle from "./components/NotificationsDropdownToggle";
import { Dropdown } from "react-bootstrap";
import ClientNotificationsList from "./components/ClientNotificationsList";
import styled from "styled-components";
import StaffNotificationsList from "./components/StaffNotificationsList";
import ReportNotificationsList from "./components/ReportNotificationsList";
import { useAuth } from "react-oidc-context";
import { MessageThreadModal } from "../messagethread";

const NotificationsWrapper = styled.div`
    margin-bottom: 20px;

`;

export const NotificationsDisplay = ({ className, isOpen = false, onToggle = null, totalPagesToDisplay, openPageOnMaxPagesReached, setThread, ...rest }) => {
    return <NotificationsWrapper className={`${className}`} {...rest}>
        {isOpen && <React.Fragment>
            <ClientNotificationsList totalPagesToDisplay={totalPagesToDisplay} openPageOnMaxPagesReached={openPageOnMaxPagesReached} />
            <StaffNotificationsList totalPagesToDisplay={totalPagesToDisplay} openPageOnMaxPagesReached={openPageOnMaxPagesReached} onToggle={onToggle} setThread={setThread} />
            <ReportNotificationsList totalPagesToDisplay={totalPagesToDisplay} openPageOnMaxPagesReached={openPageOnMaxPagesReached} />
        </React.Fragment>}
    </NotificationsWrapper>
};

const Notifications = () => {
    const dropdownId = useId();
    const [isOpen, setIsOpen] = useState(false);
    const { user: { profile } } = useAuth();
    const { sub: userId } = profile || {};
    const [autoClose, setAutoClose] = useState("outside");
    const [thread, setThread] = useState(null);

    const handleOnToggle = () => setIsOpen(prev => !prev);

    useEffect(() => {
        if (thread) {
            const interval = setInterval(() => {
                setAutoClose("inside");
            }, 100);

            return () => {
                clearInterval(interval);
            }
        }

        setAutoClose("outside");

    }, [thread]);

    return <React.Fragment>
        <Dropdown show={isOpen} className="caret-off" autoClose={autoClose} onToggle={handleOnToggle}>
            <Dropdown.Toggle
                id={dropdownId}
                as={NotificationsDropdownToggle}
                onToggle={handleOnToggle}
            />
            <Dropdown.Menu
                as={NotificationsDropdownMenu}
                show={isOpen}
            >
                <NotificationsDisplay onToggle={handleOnToggle} setThread={setThread} openPageOnMaxPagesReached totalPagesToDisplay={3} isOpen={isOpen} />
            </Dropdown.Menu>
        </Dropdown>
        <MessageThreadModal
            show={thread !== null}
            threadId={thread}
            handleClose={() => {
                setThread(null);
                // handleOnToggle();
            }}
            userId={userId}
        />
    </React.Fragment>;
};

export default Notifications;