import { useCallback } from "react";
import { MessagePriorities } from "../../../helpers/constants";
import { useCreateMessageWithAttachmentsForUserMutation, useUploadMessageAttachmentsForUserMutation } from "../../../services/messages";
import { useAuth } from "react-oidc-context";

const useCreateMessageForm = () => {

    const { user: { profile } } = useAuth();
    const [create, createResult] = useCreateMessageWithAttachmentsForUserMutation();
    const [upload, uploadResult] = useUploadMessageAttachmentsForUserMutation();

    const formDefaultValues = {
        masterAccount: null,
        message: null,
        subject: null,
        recipientIds: [],
        messagePriority: MessagePriorities.Medium,
        deadlineDate: null,
        isActionRequired: false,
        senderId: profile.sub.replace(/-/g, ''),
        project: null,
        attachments: [],
        attachmentsInfo: []
    };

    const createMessage = (data, sendOnCreate) => {
        const request = convertToFormData(data, sendOnCreate);
        return new Promise((resolve, reject) => {
            return create({ userId: profile.sub.replace(/-/g, ''), request })
                .unwrap()
                .then(resolve, reject);
        });
    };

    const retryDocumentUploads = (messageId, attachments, attachmentInfos) => {
        const request = new FormData();
        request.append('messageId', messageId);
        request.append("userId", profile.sub.replace(/-/g, ''));

        attachments?.forEach((file, index) => request.append(file.name, file, file.name));
        attachmentInfos?.forEach((info, i) => {
            request.append(`fileDetails[${i}].fileName`, info.fileName);
            request.append(`fileDetails[${i}].description`, info.description);
            request.append(`fileDetails[${i}].type`, info.type);
            request.append(`fileDetails[${i}].function`, info.selector);
        });

        return new Promise((resolve, reject) => {
            return upload({ userId: profile.sub.replace(/-/g, ''), request, messageId })
                .unwrap()
                .then(resolve, reject);
        });
    };

    const convertToFormData = useCallback((data, sendOnCreate) => {
        const newFormData = new FormData();
        const { attachments, attachmentsInfo, masterAccount, message, subject, recipientIds, messagePriority, deadlineDate, isActionRequired, isReceiptRequested, senderId, project } = data;

        attachments?.forEach((file) => newFormData.append(file.name, file, file.name));
        attachmentsInfo?.forEach((info, i) => {
            newFormData.append(`fileDetails[${i}].fileName`, info.fileName);
            newFormData.append(`fileDetails[${i}].description`, info.description);
            newFormData.append(`fileDetails[${i}].type`, info.type);
            newFormData.append(`fileDetails[${i}].function`, info.selector);
        });

        newFormData.append("userId", profile.sub.replace(/-/g, ''))

        if (masterAccount) {
            newFormData.append("masterAccountId", masterAccount.value);
        }

        if (project) {
            newFormData.append("projectId", project.value);
        }

        newFormData.append("sendOnCreate", sendOnCreate)

        newFormData.append("message", message ?? "");

        newFormData.append("threadSubject", subject ?? "");

        recipientIds.forEach(id => {
            newFormData.append("recipientIds", id);
        });


        newFormData.append("messagePriority", messagePriority);

        newFormData.append("isActionRequired", isActionRequired);

        newFormData.append("isDone", false);

        if (isActionRequired) {
            newFormData.append("todo", true);
        }

        if (isActionRequired) {
            newFormData.append("isReceiptRequested", isReceiptRequested ?? false);
        }
        
        newFormData.append("senderId", senderId);

        newFormData.append("statusText", "");

        if (deadlineDate && isActionRequired) {
            newFormData.append("deadlineDate", deadlineDate);
        }

        return newFormData;
    }, []);

    return {
        createResult,
        formDefaultValues,
        createMessage,
        retryDocumentUploads
    };
};

export default useCreateMessageForm;