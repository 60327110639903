import _ from "lodash";
import { wealthProApi } from '../../redux/api';
import { applyPatch } from "fast-json-patch";
import { socket } from "../../redux/socket";

const buildFetchRemindersQuery = ({
    masterAccountId = null,
    projectId = null,
    showCompleted = null,
    showCancelled = null,
    page = 1,
    limit = 50
}) => {
    let baseQuery = `api/reminders/reminders?page=${page}&limit=${limit}`;
    if (masterAccountId) {
        baseQuery += `&masterAccountId=${masterAccountId}`;
    }
    if (projectId) {
        baseQuery += `&projectId=${projectId}`;
    }
    if (showCompleted != null) {
        baseQuery += `&showCompleted=${showCompleted === true ? 'true' : 'false'}`;
    }
    if (showCancelled != null) {
        baseQuery += `&showCancelled=${showCancelled === true ? 'true' : 'false'}`;
    }
    return baseQuery;
};

const remindersApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["reminderTasks"]
}).injectEndpoints({
    endpoints: (builder) => ({
        createReminderTask: builder.mutation({
            query: ({ data }) => ({
                url: `api/reminders/reminders`,
                method: 'POST',
                body: data
            })
        }),
        createReminderTaskWithAttachments: builder.mutation({
            query: ({ data }) => ({
                url: `api/reminders/reminders/withAttachments`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: ["reminderTasks"]
        }),
        fetchReminderTasks: builder.query({
            query: (props) => buildFetchRemindersQuery(props),
            onCacheEntryAdded: async ({ projectId }, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) => {
                try {
                    await cacheDataLoaded;
                    if (!projectId) return;
                    
                    socket.emit('subscribe-reminders', { room: `${projectId.replace(/-/g, '')}_reminders` });

                    socket.on('reminders.created', json => {
                        const reminder = JSON.parse(json);

                        updateCachedData(data => ({
                            ...data,
                            totalCount: data.totalCount + 1,
                            results: [reminder, ...data.results]
                        }));
                    });

                    socket.on('reminders.deleted', json => {
                        const reminder = JSON.parse(json);

                        updateCachedData(data => ({
                            ...data,
                            totalCount: data.totalCount - 1,
                            results: data.results.filter(x => x.id !== reminder.id.replace(/-/g, ''))
                        }));
                    });

                    socket.on('reminders.patched', json => {
                        const reminder = JSON.parse(json);

                        updateCachedData(data => {
                            let item = data.results.find(x => x.id === reminder.id);
                            item && Object.assign(item, reminder);
                        });
                    });
                }
                catch {

                }
                await cacheEntryRemoved;

                socket.emit('unsubscribe-reminders', { room: `${projectId.replace(/-/g, '')}_reminders` });
                socket.on('reminders.created')
                socket.on('reminders.deleted')
                socket.on('reminders.patched')

            },
        }),
        getMasterAccountReminderTaskSummary: builder.query({
            query: ({ masterAccountId }) => `/api/reminders/masteraccounts/${masterAccountId}/reminders`
        }),
        getReminderTask: builder.query({
            query: ({ reminderId }) => `api/reminders/reminders/${reminderId}`
        }),
        patchReminderTask: builder.mutation({
            query: ({ reminderId, operations }) => ({
                url: `api/reminders/reminders/${reminderId}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ reminderId, cacheKey, operations }, { dispatch, queryFulfilled }) {
                const patchIndividualResult = dispatch(wealthProApi.util.updateQueryData('getReminderTask', { reminderId }, (data) => {
                    applyPatch(data, operations, true);
                }));
                const patchListResult = dispatch(wealthProApi.util.updateQueryData('searchReminderTasks', { cacheKey }, (data) => {
                    let item = data.results.find(x => x.id === reminderId);
                    applyPatch(item, operations, true);
                }))
                try {
                    await queryFulfilled;
                }
                catch {
                    patchIndividualResult.undo();
                    patchListResult.undo();
                }
            }
        }),
        searchReminderTasks: builder.query({
            query: ({ next, limit = 15, sortAscending = true, filter = {} }) => ({
                url: `api/reminders/reminders/search?limit=${limit}&sortAscending=${sortAscending}` + `${next ? '&next=' + next : ""}`,
                method: 'POST',
                body: filter
            }),
            serializeQueryArgs: ({ queryArgs: { cacheKey }, endpointName }) => `${endpointName}_${cacheKey}`,
            transformResponse: (response, _, { cacheKey }) => {
                return { cacheKey, ...response };
            },
            merge: (currentCache, newItems) => {
                if (_.isEqual(currentCache.cacheKey, newItems.cacheKey)) {
                    return {
                        ...newItems,
                        results: [...currentCache.results, ...newItems.results]
                    };
                }
                else {
                    return newItems;
                }
            },
            onCacheEntryAdded: async ({ filter: { userIds } }, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) => {
                try {
                    await cacheDataLoaded;

                    userIds.forEach(userId => {
                        socket.emit('subscribe-reminders', { room: `${userId.replace(/-/g, '')}_reminders` });

                        socket.on('reminders.created', json => {
                            const reminder = JSON.parse(json);

                            updateCachedData(data => ({
                                ...data,
                                totalCount: data.totalCount + 1,
                                results: [reminder, ...data.results]
                            }));
                        });

                        socket.on('reminders.deleted', json => {
                            const reminder = JSON.parse(json);

                            updateCachedData(data => ({
                                ...data,
                                totalCount: data.totalCount - 1,
                                results: data.results.filter(x => x.id !== reminder.id.replace(/-/g, ''))
                            }));
                        });

                        socket.on('reminders.patched', json => {
                            const reminder = JSON.parse(json);

                            updateCachedData(data => {
                                let item = data.results.find(x => x.id === reminder.id);
                                item && Object.assign(item, reminder);
                            });
                        });
                    });
                }
                catch {

                }
                await cacheEntryRemoved;
                userIds.forEach(userId => {
                    socket.emit('unsubscribe-reminders', { room: `${userId.replace(/-/g, '')}_reminders` });
                    socket.on('reminders.created')
                    socket.on('reminders.deleted')
                    socket.on('reminders.patched')
                });
            },
            forceRefetch: ({ currentArg, previousArg }) => {
                const { next: currPage } = currentArg || { next: 1 };
                const { next: prevPage } = previousArg || { next: 0 };
                return currPage !== prevPage;
            },
        }),
        uploadReminderAttachment: builder.mutation({
            query: ({ reminderId, masterAccountId, request }) => ({
                url: `api/reminders/reminders/${reminderId}/attachments` + (masterAccountId ? `?masterAccountId=${masterAccountId}` : ""),
                method: 'POST',
                body: request
            }),
            async onQueryStarted({ reminderId, cacheKey }, { dispatch, queryFulfilled }) {
                try {
                    const { data: attachments } = await queryFulfilled;
                    dispatch(wealthProApi.util.updateQueryData('searchReminderTasks', { cacheKey }, ({ results }) => {
                        let item = results.find(x => x.id === reminderId);
                        item.attachments = [...item.attachments, ...attachments.filter(x => x.status === 200)];
                    }))
                }
                catch { }
            }
        })
    })
});

export const {
    useCreateReminderTaskMutation,
    useCreateReminderTaskWithAttachmentsMutation,
    useFetchReminderTasksQuery,
    useGetMasterAccountReminderTaskSummaryQuery,
    useLazyFetchReminderTasksQuery,
    useLazyGetReminderTaskQuery,
    usePatchReminderTaskMutation,
    useLazySearchReminderTasksQuery,
    useUploadReminderAttachmentMutation
} = remindersApiEndpoints;