import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import MomentComponent from "react-moment";
import styled from "styled-components";
import { Button, IconButton } from "../../../components";
import { FormLabel, FormTextArea } from "../../../components/forms";
import { CenterFormColumnComponent } from "../../../components/utilities";
import useOpenDocument from "../../../hooks/useOpenDocument";
import CreateReportModal from "../../createreportmodal";
import SelectReportModal from "./SelectReportModal";
import TargetDate from "./TargetDate";

const StatusDisplay = styled.span`
        font-size: 16px;
        font-weight:bold;
    `;

const PreReviewDocument = ({ review, onFieldChanged, select, amberWarning = 5 }) => {

    const { openDocument, isFetching: isOpening } = useOpenDocument();

    const [showCreateReport, setShowCreateReport] = useState(false);
    const handleCreateReportClose = () => setShowCreateReport(false);
    const handleOnReportCreated = (r) => {
        //Open Report?
    };

    const [showSelectReport, setShowSelectReport] = useState(false);
    const handleSelectReportClose = () => setShowSelectReport(false);
    const handleOnReportSelected = (report) => {
        return select(report);
        //Open Report?
    }

    return (
        <React.Fragment>

            <CreateReportModal
                show={showCreateReport}
                defaultProjectId={review?.projectId}
                handleClose={handleCreateReportClose}
                onReportCreated={handleOnReportCreated}
            />
            <SelectReportModal
                show={showSelectReport}
                handleClose={handleSelectReportClose}
                onReportSelected={handleOnReportSelected}
                reportType="Pre Review Document"
            />
            <div className="row mt-4">
                <div className="col-2">
                    <div className="row">
                        <FormLabel className="col">
                            PRD Status
                        </FormLabel>
                    </div>
                    <div className="row">
                        <StatusDisplay className="col">
                            {review.preReviewDocumentStatusText}
                        </StatusDisplay>
                    </div>
                </div>

                <div className="col">
                    <FormTextArea
                        label="Comment"
                        value={review.preReviewDocumentStatusComment}
                        onBlur={(value) =>
                            onFieldChanged(
                                "preReviewDocumentStatusComment",
                                value
                            )
                        }
                        disabled={!review.preReviewDocumentRequired}
                    />
                </div>

                <div className="col-2 align-items-end">
                    <FormLabel className="col">Pre Review Document</FormLabel>
                    {review.preReviewDocumentReportId != null && review.preReviewDocumentGraphId != null &&
                        <Button
                            variant="primary"
                            className="w-100"
                            onClick={() => openDocument(review.preReviewDocumentGraphId)}
                            disabled={!review.preReviewDocumentRequired || isOpening}
                        >
                            Open
                        </Button>
                    }
                    {review.preReviewDocumentReportId == null &&
                        <Dropdown>
                            <Dropdown.Toggle
                                variant="success"
                                className="w-100 flex-fill"
                                disabled={!review.preReviewDocumentRequired || review?.reviewStatus === 1 || review?.reviewStatus === 2}
                            >
                                Create/Select
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => setShowCreateReport(true)}>Create Report</Dropdown.Item>
                                <Dropdown.Item onClick={() => setShowSelectReport(true)}>Select Existing</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                </div>
                <div className="col-md-auto d-flex align-items-end">
                    <CenterFormColumnComponent>
                        <IconButton
                            className="col-auto"
                            icon={review.preReviewDocumentRequired ? "fa-x" : "fa-check"}
                            variant={review.preReviewDocumentRequired ? "danger" : "success"}
                            onClick={(_) =>
                                onFieldChanged(
                                    "preReviewDocumentRequired",
                                    !review.preReviewDocumentRequired
                                )
                            }
                        />
                    </CenterFormColumnComponent>
                </div>
            </div>

            <div className="row">
                <div className="col-3">
                    {review.preReviewDocumentAuthor && review.preReviewDocumentAuthorAssignedDate && (
                        <FormLabel>
                            Assigned to {review.preReviewDocumentAuthor}
                            <MomentComponent
                                className="ps-1"
                                format="DD/MM/YYYY HH:mm">
                                {review.preReviewDocumentAuthorAssignedDate}
                            </MomentComponent>
                        </FormLabel>
                    )}
                </div>

                <div className="col-6">
                    <div className="row">
                        <div className="col d-flex justify-content-start">
                            {review.preReviewDocumentCompliantDate && (
                                <FormLabel>
                                    Compliant:
                                    <MomentComponent
                                        className="ps-1"
                                        format="DD/MM/YYYY HH:mm"
                                    >
                                        {
                                            review.preReviewDocumentCompliantDate
                                        }
                                    </MomentComponent>
                                </FormLabel>
                            )}
                        </div>
                        <div className="col d-flex justify-content-start">
                            {review.preReviewDocumentSentDate && (
                                <FormLabel>
                                    Sent:
                                    <MomentComponent
                                        className="ps-1"
                                        format="DD/MM/YYYY HH:mm"
                                    >
                                        {review.preReviewDocumentSentDate}
                                    </MomentComponent>
                                </FormLabel>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-3 d-flex justify-content-end">
                    {review.preReviewDocumentSentTarget && (
                        <TargetDate date={review.preReviewDocumentSentDate} targetDate={review.preReviewDocumentSentTarget} />
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default PreReviewDocument;
