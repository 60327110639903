import { useCallback, useEffect, useMemo, useRef } from "react";
import { patchReplace } from "../../../helpers/patchDoc";
import { useInstruction } from "../contexts/InstructionContext";

const useIncome = () => {
    const [{ patchInvest: patchInvestTrigger, realTimePatchInvest }, { instructionId, realTimeInstruction, invest, divest }, { incomeFrequencyOptions, calculateIncomeTotal, realTimeIncomeTotal, realTimeRemainder }] = useInstruction();

    const patchInvest = useCallback((property, value) =>
        patchInvestTrigger({ investId: invest?.id, instructionId, operations: [patchReplace(property, value)] }).unwrap(),
        [instructionId, invest?.id, patchInvestTrigger]);

    const realTimePatchInvestSingle = useCallback((property, value) => {
        let operations = [patchReplace(property, value)];

        realTimePatchInvest(operations);
    }, [realTimePatchInvest]);

    const cachedIncomeTotal = useMemo(() =>
        calculateIncomeTotal(invest?.clientWithdrawalAmount, invest?.clientIncomeAmount, invest?.clientIncomeFrequency, invest?.taxFreeCashAmount, invest?.toCashAccount, invest?.retainAmount),
        [calculateIncomeTotal, invest]);

    const cachedRemainder = useMemo(() =>
        Math.max(divest?.totalSaleProceeds - cachedIncomeTotal, 0),
        [cachedIncomeTotal, divest?.totalSaleProceeds]);

    const firstRealTimeUpdate = useRef(true);
    const firstCacheUpdate = useRef(true);

    // Real-time update of total investment amount when values in this section change
    useEffect(() => {
        if (firstRealTimeUpdate.current) {
            firstRealTimeUpdate.current = false
            return;
        }

        const updateInvestmentAmountTimeout = setTimeout(() =>
            realTimePatchInvestSingle("totalInvestmentAmount", realTimeRemainder), 150);

        return () => clearTimeout(updateInvestmentAmountTimeout);
    }, [realTimePatchInvestSingle, realTimeRemainder]);

    // onBlur update of total investment amount when values in this section change
    useEffect(() => {
        if (firstCacheUpdate.current) {
            firstCacheUpdate.current = false
            return;
        }

        const updateInvestmentAmountTimeout = setTimeout(() =>
            patchInvest("totalInvestmentAmount", cachedRemainder), 150);

        return () => clearTimeout(updateInvestmentAmountTimeout);
    }, [cachedRemainder, patchInvest]);

    const showTransferWarning = useMemo(() =>
        realTimeInstruction?.divest?.totalSaleProceeds - realTimeIncomeTotal - realTimeRemainder < 0,
        [realTimeInstruction?.divest?.totalSaleProceeds, realTimeIncomeTotal, realTimeRemainder]);

    return {
        incomeFrequencyOptions,
        patchInvest,
        realTimePatchInvest,
        realTimePatchInvestSingle,
        showTransferWarning
    }

}

export default useIncome;