import { useParams } from "react-router-dom";
import { useLazyFetchFolderMessagesForUserQuery, useMoveMailToFolderMutation } from "../../../services/useremails";
import { usePersonalOutlookContext } from "../../../hooks/PersonalOutlookContext";
import { useEffect, useState } from "react";

const useMessageSummaries = () => {

    const { folderId } = useParams();
    const { selectedMailUserId: userId } = usePersonalOutlookContext();
    const [isClearing, setIsClearing] = useState(false);
    const [fetchMessageSummaries, { data, isLoading, isError, error, isUninitialized }] = useLazyFetchFolderMessagesForUserQuery();
    const [moveToFolderTrigger, moveToFolderResult] = useMoveMailToFolderMutation();
    const { value, nextLink } = data || { value: [], nextLink: null };


    const clear = (e) => {
        if (typeof (e?.preventDefault) === "function") {
            e.preventDefault();
        }
        setIsClearing(true);

        fetchMessageSummaries({ folderId, userId }, true)
            .unwrap()
            .finally(_ => setIsClearing(false));
    };

    const isItemLoaded = (index) => value[index] || index < value.length;

    const loadMoreItems = () => {
        fetchMessageSummaries({ folderId, userId, nextLink: nextLink ? encodeURIComponent(nextLink) : null });
    };

    const moveMailToDeletedFolder = (id) => {
        return new Promise((res, rej) => {
            return moveToFolderTrigger({ userId, id, folderId, request: { delete: true } })
                .unwrap()
                .then(res, rej);
        });
    };

    useEffect(() => {
        folderId && !isUninitialized && clear();
    }, [folderId]);

    return {
        isClearing,
        isLoading,
        isError,
        error,
        value,
        totalCount: nextLink || isUninitialized ? value.length + 15 : value.length,
        isItemLoaded,
        loadMoreItems,
        moveMailToDeletedFolder,
        moveToFolderResult,
        retry: clear,
    };
};

export default useMessageSummaries;