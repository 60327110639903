import { wealthProApi } from "../../redux/api";

const applyRecipientToGridMessage = (recipient, gridMessage) => {
    gridMessage.userMessagePriority = recipient.recipientPriority;
    gridMessage.userReminderDate = recipient.reminderDate;
};

const userMessageRecipientsApiEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        patchRecipientByUserId: builder.mutation({
            query: ({ userId, messageId, operations }) => ({
                url: `api/messaging/users/${userId}/messages/${messageId}/recipients`,
                method: "PATCH",
                body: operations
            }),
            async onQueryStarted({ userId, messageId, operations }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    dispatch(wealthProApi.util.updateQueryData('searchUserMessages', { userId }, ({ results }) => {
                        let item = results.find(x => x.messageId === messageId);

                        applyRecipientToGridMessage(data, item);
                    }));
                }
                catch { }
            }
        })
    })
});

export const {
    usePatchRecipientByUserIdMutation
} = userMessageRecipientsApiEndpoints;