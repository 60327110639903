import React from "react";
import withProviders from "../../hooks/withProviders";
import ClientReportsHistoryModal from "../clientreportshistorymodal";
import ReportManagementDetailTableHeader from "./components/ReportManagementDetailTableHeader";
import ReportManagementDetailTableRow from "./components/ReportManagementDetailTableRow";
import useReportManagementDetails from "./hooks/useReportManagementDetails";
import { VirtualTable } from "../../components/tables";
import { TableRecordCountDisplayFooter } from "../../components/tables/VirtualTable";
import { EditClientAuthorModal } from "../clientreportseditmodal";
import { useEditReport } from "../clientreportseditmodal/hooks/EditReportContext";
import { EditReportProvider } from "../clientreportseditmodal/hooks/EditReportContext";
import { ReportHistoryProvider, useReportHistory } from "../clientreportshistorymodal/hooks/ReportHistoryContext";

const ReportManagementDetailed = () => {
    const {
        clear: refreshTable,
        data,
        error,
        isNextPageLoading,
        isNewRecords,
        isRowLoaded,
        loadMore,
        newRecordsSinceCache,
        sort,
        sortTable,
        totalCount
    } = useReportManagementDetails();
    const { clear, isLoading, isModalActive, report, saveChanges } = useEditReport();
    const { clear: clearSelectedHistoryReport, isModalActive: isReportHistoryShown, report: selectedHistoryReport } = useReportHistory();
    const isNewRecordsDisplayed = isNewRecords && !isLoading && !isNextPageLoading;

    return (
        <React.Fragment>
            <VirtualTable
                enableScrollTracking={true}
                row={ReportManagementDetailTableRow}
                header={(
                    <thead>
                        <tr>
                            <ReportManagementDetailTableHeader property="adviserName" sort={sort} sortTable={sortTable}>Adviser</ReportManagementDetailTableHeader>
                            <ReportManagementDetailTableHeader property="reportName" sort={sort} sortTable={sortTable}>Report</ReportManagementDetailTableHeader>
                            <ReportManagementDetailTableHeader property="masterAccountName" sort={sort} sortTable={sortTable}>Client</ReportManagementDetailTableHeader>
                            <ReportManagementDetailTableHeader property="createdDate" sort={sort} sortTable={sortTable}>Created</ReportManagementDetailTableHeader>
                            <ReportManagementDetailTableHeader property="statusDescription" sort={sort} sortTable={sortTable}>Status</ReportManagementDetailTableHeader>
                            <ReportManagementDetailTableHeader property="authorName" sort={sort} sortTable={sortTable}>Author</ReportManagementDetailTableHeader>
                            <ReportManagementDetailTableHeader property="deadlineDate" sort={sort} sortTable={sortTable}>Deadline</ReportManagementDetailTableHeader>
                            <ReportManagementDetailTableHeader property="rejectCount" sort={sort} sortTable={sortTable} className="text-center">Quality</ReportManagementDetailTableHeader>
                            <ReportManagementDetailTableHeader property="compliantDate" sort={sort} sortTable={sortTable}>Compliant</ReportManagementDetailTableHeader>
                            <ReportManagementDetailTableHeader property="sentDate" sort={sort} sortTable={sortTable}>Sent</ReportManagementDetailTableHeader>
                            <th className="sticky-header">&nbsp;</th>
                        </tr>
                    </thead>
                )}
                footer={
                    <TableRecordCountDisplayFooter 
                        totalCount={totalCount}
                        isNewRecordsDisplayed={isNewRecordsDisplayed}
                        newRecordsSinceCache={newRecordsSinceCache}
                        reload={refreshTable}
                    />
                }
                error={error}
                isLoading={isNextPageLoading}
                isItemLoaded={isRowLoaded}
                loadMoreItems={loadMore}
                itemSize={50}
                itemCount={totalCount === data.length ? totalCount : data.length + 3}
                itemData={data}
            />
            <ClientReportsHistoryModal 
                report={selectedHistoryReport}
                show={isReportHistoryShown}
                handleClose={clearSelectedHistoryReport}
            />
            <EditClientAuthorModal 
                report={report}
                show={isModalActive}
                isLoading={isLoading}
                handleSave={(ops) => saveChanges(ops).then(clear)}
                handleClose={clear}
            />
        </React.Fragment>
    );
};

export default withProviders(EditReportProvider, ReportHistoryProvider)(ReportManagementDetailed);