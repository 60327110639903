import React from "react";
import useApplications from "../hooks/useApplications";
import { VirtualTable } from "../../../components/tables";
import ApplicationTableRow from "./ApplicationTableRow";
import { Main } from "../../../components/utilities";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import ApplicationTableHeader from "./ApplicationsTableHeader";

const ApplicationsGrid = () => {
    const {
        loadMore,
        isRowLoaded,
        sortableHeaderRenderer,
        clear,
        results,
        totalCount,
        columns,
        error,
        isClearing,
        isError,
    } = useApplications();

    if (isError) {
        return <ErrorMessageWrapper>
            <ErrorMessage error={error} retryCallback={clear} />
        </ErrorMessageWrapper>
    }

    return (
        <VirtualTable
            enableScrollTracking={false}
            loadMoreItems={loadMore}
            row={ApplicationTableRow}
            itemSize={42}
            itemCount={results.length === totalCount ? totalCount : results.length + 10}
            itemData={{results, isClearing}}
            isItemLoaded={isRowLoaded}
            header={<ApplicationTableHeader columns={columns} renderer={sortableHeaderRenderer}/>}
            // footer={<ApplicationsTableFooter totalCount={totalCount}/>}
        />
    );
};

export default ApplicationsGrid;