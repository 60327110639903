export const documentFunctions = {
  other: '03abc5d507fa4aa0a6804a8de649004b'
};

export const documentFunctionSelectors = {
  other: 0,
  adviceReport: 1,
  businessSheet: 2,
  research: 3,
  serviceAgreement: 4,
  sourceOfFunds: 5,
  antiMoneyLaundering: 6,
  policyDocument: 7,
  annualStatement: 8,
  providerForm: 9,
  contractNote: 10,
  administration: 11,
  taxPack: 12,
  custodyStatement: 13,
  costDisclosure: 14,
  lastWillAndTestament: 15,
  executryRelated: 16,
  factFind: 17
};

export const riskGradeKeys = {
  low: "lowToModerate",
  high: "highRisk"
}

export const serviceFeeTypes = {
  percentage: "percentage",
  amount: "amount"
};

export const adviceFeeTypes = {
  percentage: "percentage",
  amount: "amount"
};

export const ExternalApis = {
  webDav: 'ms-word:ofv|u|https://webdavserver.net/Userb490e44/'
}

export const feeCollectionMethods = {
  trading: "tradingAccount"
};

export const UserRoles = {
  sysAdmin: 'sysadmin'
};

export const DelegatedAccessTypes = {
  readCalendar: "ReadCalendar",
  writeCalendar: "WriteCalendar",
  readMail: "ReadMail",
  writeMail: "WriteMail",
  sendMail: "SendMail",
  readMessages: "ReadMessages",
  writeMessages: "WriteMessages",
  readReminders: "ReadReminders",
  writeReminders: "WriteReminders"
};

export const MessagePriorities = {
  Low: 10,
  Medium: 20,
  High: 30
};

export const ReminderStatuses = {
  Active: 0,
  Cancelled: 2,
};

export const DocumentTypes = {
  Word: 0,
  Excel: 1,
  OutlookItem: 2,
  EmailFile: 3,
  PDF: 4,
  Text: 5,
  Image: 6,
  Video: 7
}

export const DocumentTypeIcons = {
  [DocumentTypes.Word]: {
    icon: 'fa-file-word',
    color: "#00a2ed"
  },
  [DocumentTypes.Excel]: {
    icon: 'fa-file-excel',
    color: " #008000"
  },
  [DocumentTypes.OutlookItem]: {
    icon: 'fa-file-code',
    color: "#0072c6"
  },
  [DocumentTypes.EmailFile]: {
    icon: 'fa-file-code',
    color: "#0072c6"
  },
  [DocumentTypes.PDF]: {
    icon: 'fa-file-pdf',
    color: "#f40f02"
  },
  [DocumentTypes.Text]: {
    icon: 'fa-file-lines',
    color: "#000000"
  },
  [DocumentTypes.Image]: {
    icon: 'fa-file-image',
    color: "#000000"
  },
  [DocumentTypes.Video]: {
    icon: 'fa-file-video',
    color: "#000000"
  }
};

export const exceptionResolutionTypes = {
  Accept: 0,
  Partial: 1,
  Regular: 2,
  Exception: 3,
  // Below are old types and aren't required
  // Tag: 4,
  // Sedol: 5,
  // Phased: 6,
  Error: 7,
  TopUpCash: 8
};