import React from "react";

const ReportHistoryContext = React.createContext();

export const useReportHistory = () => React.useContext(ReportHistoryContext);

export const ReportHistoryProvider = ({ children, showModalOnReportSet = true }) => {
    const [report, _setReport] = React.useState(null);
    const [showModal, _setShowModal] = React.useState(false);

    const clear = () => {
        _setReport(_ => null);
        _setShowModal(_ => false);
    };

    const setReport = (report) => {
        _setReport(_ => report);
        if (showModalOnReportSet === true) {
            _setShowModal(_ => true);
        }
    };

    return (
        <ReportHistoryContext.Provider value={{ 
            clear,
            isModalActive: showModal,
            report,
            setReport
        }}>
            {children}
        </ReportHistoryContext.Provider>
    );
};