import { applyPatch } from "fast-json-patch";
import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { patchReplace } from "../../../helpers/patchDoc";
import {
    useAddDivestTransactionMutation,
    usePatchDivestTransactionMutation,
    useRecsFetchTransactionTypesQuery,
    useRemoveDivestTransactionMutation
} from "../../../services/recommendations";
import { useInstruction } from "../contexts/InstructionContext";

// Constant % widths for designing table column sizes
const instructionSelectionWidth = 20;
const percentageInputWidth = 10;
const valueWidth = 10;
const investmentNameInputWidth = 27;
const sedolWidth = 9;
const holdingWidth = 8;

const realtimeTransactionsReducer = (state, action) => {
    switch (action.type) {
        case 'init':
            return action.value;
        case 'add':
            return [
                ...state,
                {
                    divestId: action.divestId
                }
            ];
        case 'set':
            return [
                ...state.slice(0, -1),
                action.newTransaction
            ];
        case 'patch':
            if (!state?.[action.index])
                return state;
            let transactionCopy = { ...state[action.index] };

            applyPatch(transactionCopy, action.operations);

            return [
                ...state.slice(0, action.index),
                transactionCopy,
                ...state.slice(action.index + 1)
            ];
        case 'bulk-patch':
            return state.map(action.patch);
        case 'delete':
            return state?.filter(transaction => transaction.rowTag !== action.rowTag) ?? [];
        case 'undo-delete':
            return [
                ...state,
                action.deletedTransaction
            ]
        default:
            break;
    }
}

const useDivestSelection = (isSelling) => {

    const [{
        patchDivest: patchDivestTrigger,
        realTimePatchDivest,
        fetchDivestTransactions
    }, {
        instructionId,
        realTimeInstruction,
        divest,
        divestTransactions,
        divestTransactionsIsError,
        divestTransactionsIsLoading,
        divestTransactionsIsFetching,
    }] = useInstruction();

    const gridTemplateColsArray = useMemo(() => isSelling ?
        [
            sedolWidth,
            investmentNameInputWidth,
            valueWidth,
            holdingWidth,
            instructionSelectionWidth,
            percentageInputWidth,
            valueWidth,
        ] :
        [
            sedolWidth,
            investmentNameInputWidth,
            valueWidth,
            valueWidth,
            holdingWidth,
            instructionSelectionWidth,
            percentageInputWidth,
        ], [isSelling]);

    const [totalCurrentValue, setTotalCurrentValue] = useState(0);

    const [createDivestTransaction] = useAddDivestTransactionMutation();
    const [patchDivestTransaction] = usePatchDivestTransactionMutation();
    const [deleteDivestTransaction] = useRemoveDivestTransactionMutation();

    const [realTimeTransactions, dispatch] = useReducer(realtimeTransactionsReducer, []);

    useEffect(() => {
        if (divest?.id) {
            fetchDivestTransactions({ divestId: divest?.id })
                .unwrap()
                .then(res => {
                    dispatch({
                        type: 'init',
                        value: res
                    });
                });
        }
    }, [divest?.id, fetchDivestTransactions]);

    useEffect(() => {
        dispatch({ type: 'init', value: divestTransactions });
    }, [divestTransactions])

    const {
        data: transactionTypes,
        isSuccess: objectsIsReady,
        isLoading: objectsIsLoading,
        isFetching: objectsIsFetching,
        isError: objectsIsError,
        refetch: refetchObjects
    } = useRecsFetchTransactionTypesQuery({ isSelling, listType: "list" });

    const transactionTypeSelectObjects = useMemo(() => transactionTypes?.map(type => ({
        label: type?.description,
        value: type?.id
    })), [transactionTypes]);

    // Possible TODO:
    // This was removed to prevent "Too Many Requests"
    // const {
    //     data: transactionTypeSelectObjects,
    //     isSuccess: optionsIsReady,
    //     isLoading: optionsIsLoading,
    //     isFetching: optionsIsFetching,
    //     isError: optionsIsError,
    //     refetch: refetchOptions
    // } = useRecsFetchTransactionTypesQuery({ isSelling, listType: "select" });


    const transactionTypesIsReady = /* optionsIsReady && */ objectsIsReady;
    const transactionTypesIsLoading = /* optionsIsLoading || */ objectsIsLoading;

    const isError = useMemo(() => {
        return /* optionsIsError || */ objectsIsError || divestTransactionsIsError;
    }, [divestTransactionsIsError, objectsIsError /* optionsIsError */]);

    const retry = useCallback(() => {
        // refetchOptions();
        refetchObjects();
        fetchDivestTransactions({ divestId: divest?.id })
            .unwrap()
            .then(res => {
                dispatch({
                    type: 'init',
                    value: res
                });
            });
    }, [divest?.id, fetchDivestTransactions, refetchObjects/* , refetchOptions */])

    const isLoading = useMemo(() => /* optionsIsLoading || optionsIsFetching
        || */ objectsIsLoading || objectsIsFetching
        || divestTransactionsIsLoading || divestTransactionsIsFetching,
        [divestTransactionsIsFetching, divestTransactionsIsLoading, objectsIsFetching, objectsIsLoading/* , optionsIsFetching, optionsIsLoading */]);

    const patchDivest = useCallback((property, value) =>
        patchDivestTrigger({ divestId: divest?.id, instructionId, operations: [patchReplace(property, value)] }).unwrap(),
        [divest?.id, instructionId, patchDivestTrigger]);

    const bulkPatchDivest = useCallback((operations) =>
        patchDivestTrigger({ divestId: divest?.id, instructionId, operations }).unwrap(),
        [divest?.id, instructionId, patchDivestTrigger]);

    const patchTransaction = useCallback((property, value, rowTag) =>
        patchDivestTransaction({ divestId: divest?.id, operations: [patchReplace(property, value)], rowTag }).unwrap(),
        [divest?.id, patchDivestTransaction]);

    const bulkPatchTransaction = useCallback((operations, rowTag) =>
        patchDivestTransaction({ divestId: divest?.id, operations, rowTag }).unwrap(),
        [divest?.id, patchDivestTransaction]);

    const realTimePatchTransaction = useCallback((property, value, index) => {
        let operations = [patchReplace(property, value)];

        dispatch({
            type: 'patch',
            operations,
            index
        });
    }, []);

    const realTimeBulkPatchTransaction = useCallback((operations, index) => {
        dispatch({
            type: 'patch',
            operations,
            index
        });
    }, []);

    const [deletedTransaction, setDeletedTransaction] = useState(null);

    const deleteTransaction = useCallback((rowTag) => {
        setDeletedTransaction(realTimeTransactions.find(transaction => transaction.rowTag === rowTag));
        dispatch({
            type: 'delete',
            rowTag
        });
        deleteDivestTransaction({ divestId: divest?.id, rowTag })
            .unwrap()
            .then(_res => {
                setDeletedTransaction(null);
            }, _err => {
                dispatch({
                    type: 'undo-delete',
                    deletedTransaction
                });
            });
    }, [deleteDivestTransaction, deletedTransaction, divest?.id, realTimeTransactions])

    const createTransaction = useCallback(() => {
        dispatch({
            type: 'add',
            divestId: divest?.id
        });
        createDivestTransaction({ divestId: divest?.id })
            .unwrap()
            .then(res => {
                dispatch({
                    type: 'set',
                    newTransaction: res
                });
            });
    }, [createDivestTransaction, divest?.id])

    useEffect(() => {
        if (divest?.id && isSelling) {
            const timeout = setTimeout(() => {
                const newTotal = realTimeTransactions?.reduce((accumulator, transaction) => {
                    return accumulator + (transaction?.currentValue ?? 0)
                }, 0);
                setTotalCurrentValue(newTotal);
            }, 100);
            return () => clearTimeout(timeout);
        }
    }, [divest?.id, realTimeTransactions, isSelling, patchDivest])

    // Real-time update of total sale proceeds (sell)
    useEffect(() => {
        const timeout = setTimeout(() => {
            if (divest?.id && isSelling && !(divestTransactionsIsLoading || divestTransactionsIsFetching)) {
                const newTotal = realTimeTransactions?.reduce((accumulator, transaction) =>
                    accumulator + (transaction?.saleValue ?? 0), 0) ?? 0;

                if (newTotal !== realTimeInstruction?.divest?.totalSaleProceeds)
                    realTimePatchDivest([patchReplace("totalSaleProceeds", newTotal)]);
            }
        }, 150);

        return () => clearTimeout(timeout);
    }, [divest?.id, divestTransactionsIsFetching, divestTransactionsIsLoading, isSelling, realTimeInstruction?.divest?.totalSaleProceeds, realTimePatchDivest, realTimeTransactions])

    // onBlur update of total sale proceeds (sell)
    useEffect(() => {
        const timeout = setTimeout(() => {
            if (divest?.id && isSelling && !(divestTransactionsIsLoading || divestTransactionsIsFetching)) {
                const newTotal = divestTransactions?.reduce((accumulator, transaction) =>
                    accumulator + (transaction?.saleValue ?? 0), 0) ?? 0;

                if (newTotal !== divest?.totalSaleProceeds)
                    patchDivest("totalSaleProceeds", newTotal);
            }
        }, 150);
        return () => clearTimeout(timeout);
    }, [divest?.id, divest?.totalSaleProceeds, divestTransactions, divestTransactionsIsFetching, divestTransactionsIsLoading, isSelling, patchDivest]);

    const calculateTotals = useCallback((accumulator, transaction) => {
        let saleValue = transaction?.saleValue || 0;
        if (transactionTypes?.find(type => type?.id === transaction?.transactionTypeId)?.transactionType === 0) // 0 is Retain
            saleValue = saleValue * (100 - (transaction?.retainPercent || 0)) / 100;

        return {
            newTotalRingFenceAmount: transactionTypes?.find(type => type?.id === transaction?.transactionTypeId)?.isRingFenced
                ? accumulator.newTotalRingFenceAmount + saleValue
                : accumulator.newTotalRingFenceAmount,
            newTotal: transactionTypes?.find(type => type?.id === transaction?.transactionTypeId)?.inTotalCashProceeds
                ? accumulator.newTotal + saleValue
                : accumulator.newTotal
        };
    }, [transactionTypes]);

    // Real-time update of total sale proceeds and ring fenced amount (switch)
    useEffect(() => {
        const timeout = setTimeout(() => {
            if (divest?.id && !isSelling) {
                const { newTotalRingFenceAmount, newTotal } = realTimeTransactions?.reduce(calculateTotals, { newTotalRingFenceAmount: 0, newTotal: 0 }) || { newTotalRingFenceAmount: 0, newTotal: 0 };

                let operations = [];

                if (newTotal !== realTimeInstruction?.divest?.totalSaleProceeds)
                    operations.push(patchReplace("totalSaleProceeds", newTotal));
                if (newTotalRingFenceAmount !== realTimeInstruction?.divest?.totalRingFenceAmount)
                    operations.push(patchReplace("totalRingFenceAmount", newTotalRingFenceAmount));

                if (operations.length > 0)
                    realTimePatchDivest(operations);
            }
        }, 150);

        return () => clearTimeout(timeout);
    }, [calculateTotals, divest?.id, isSelling, realTimeInstruction?.divest?.totalRingFenceAmount, realTimeInstruction?.divest?.totalSaleProceeds, realTimePatchDivest, realTimeTransactions])

    // onBlur update of total sale proceeds and ring fenced amount (switch)
    useEffect(() => {
        const timeout = setTimeout(() => {
            if (divest?.id && !isSelling) {
                const { newTotalRingFenceAmount, newTotal } = divestTransactions?.reduce(calculateTotals, { newTotalRingFenceAmount: 0, newTotal: 0 }) || { newTotalRingFenceAmount: 0, newTotal: 0 };

                let operations = [];

                if ((divest?.totalSaleProceeds || 0) !== newTotal)
                    operations.push(patchReplace("totalSaleProceeds", newTotal));

                if ((divest?.totalRingFenceAmount || 0) !== newTotalRingFenceAmount)
                    operations.push(patchReplace("totalRingFenceAmount", newTotalRingFenceAmount));

                if (operations.length > 0)
                    bulkPatchDivest(operations);
            }
        }, 150)

        return () => clearTimeout(timeout);
    }, [bulkPatchDivest, calculateTotals, divest?.id, divest?.totalRingFenceAmount, divest?.totalSaleProceeds, divestTransactions, isSelling])

    return {
        gridTemplateColsArray,
        divestTransactions,
        divestTransactionsIsLoading,
        realTimeTransactions,
        transactionTypeSelectObjects,
        transactionTypes,
        transactionTypesIsReady,
        transactionTypesIsLoading,
        totalCurrentValue,
        isError,
        isLoading,
        patchDivest,
        createTransaction,
        patchTransaction,
        bulkPatchTransaction,
        realTimePatchTransaction,
        realTimeBulkPatchTransaction,
        deleteTransaction,
        retry
    };
}

export default useDivestSelection;