import React, { useEffect, useState } from "react";
import { Button } from "../../components";
import { Badge, CenterArea } from "../../components/utilities";
import { usePersonalOutlookContext } from "../../hooks/PersonalOutlookContext";

import styled from "styled-components";
import { Spinner } from "../../components/loaders";
import { ErrorMessage, ErrorMessageWrapper } from "../../components/messages";
import UserDelegatesSelect from "../../components/forms/selects/UserDelegatesSelect";
import { DelegatedAccessTypes } from "../../helpers/constants";
import { useMemo } from "react";
import CreateMailModal from "../createmailmodal";

const FolderItem = styled.div`
    &.active,
    &:hover {
        background-color: ${props => props.theme.body};
    }
`;

const PersonalEmailFolders = () => {

    const [showCreateEmailModal, setShowCreateEmailModal] = useState(false);
    const {
        fetchFolders,
        folders: { data, isError, isFetching },
        selectFolder,
        selectedFolderId,
        selectedMailUserId,
        setSelectedMailUserId,
        mailDelegateRightsResult
    } = usePersonalOutlookContext();

    const { data: mailDelegateRights,  isFetching: isFetchingMailDelegateRights } = mailDelegateRightsResult || {};

    useEffect(() => {
        fetchFolders({ userId: selectedMailUserId });
    }, [selectedMailUserId]);

    const isDataLoading = useMemo(() => 
        isFetching === true ||
        isFetchingMailDelegateRights === true,
        [isFetching, isFetchingMailDelegateRights]
    );

    return (
        <React.Fragment>
            <CreateMailModal
                show={showCreateEmailModal}
                onHide={() => setShowCreateEmailModal(false)}
            />
            <div className="p-2 mb-2">
                <UserDelegatesSelect
                    accessType={DelegatedAccessTypes.readMail}
                    defaultValue={selectedMailUserId}
                    onChange={({ value }) => setSelectedMailUserId(value)}
                />
            </div>
            {isError === true && (
                <ErrorMessageWrapper>
                    <ErrorMessage message="Error loading folders." retryCallback={() => fetchFolders({ userId: selectedMailUserId })} />
                </ErrorMessageWrapper>
            )}
            {isDataLoading && (
                <CenterArea>
                    <Spinner />
                </CenterArea>
            )}
            <div className="p-3 w-100">
                {data && data.length > 0 && !isFetchingMailDelegateRights && (
                    <React.Fragment>
                        {mailDelegateRights.writeMail && <Button className="w-100 mb-2" variant="primary" onClick={() => setShowCreateEmailModal(true)}>New Email</Button>}
                        {data.map((el, i) => (
                            <FolderItem className={`d-flex p-2 my-2 w-100 rounded has-pointer ${selectedFolderId === el.id ? 'active' : ''}`} key={i} onClick={() => selectFolder(el.id)}>
                                <div>

                                </div>
                                <div className="d-flex align-items-center flex-fill">
                                    <span>{el.displayName}</span>
                                    {el.unreadItemCount > 0 && (
                                        <Badge className="mx-3" variant="danger">{el.unreadItemCount}</Badge>
                                    )}
                                </div>
                                {el.totalItemCount > 0 && (
                                    <div className="text-muted">
                                        <span>{el.totalItemCount}</span>
                                    </div>
                                )}
                            </FolderItem>
                        ))}
                    </React.Fragment>
                )}
            </div>
        </React.Fragment>
    );
};

export default PersonalEmailFolders;