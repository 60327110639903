import { useClientContext } from "../../../hooks/ClientContext";
import { useLazyFetchMessagesByThreadIdQuery } from "../../../services/messages";
import { useClientMessaging } from "../hooks/ClientMessagingContext";
import MessageList from "./MessageList";

const ThreadMessagesListWrapper = () => {

    const { id: masterAccountId } = useClientContext();
    const { threadId } = useClientMessaging();

    return <MessageList
        fetchHook={useLazyFetchMessagesByThreadIdQuery}
        queryParams={{ masterAccountId, threadId }}
    />;
};

export default ThreadMessagesListWrapper;