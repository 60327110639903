import React, { useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import styled from "styled-components";
import { Button } from "../../components";
import PageHeader from "../../components/layout/PageHeader";
import { AdministrationDashboardPageSubHeader, Main, ThemedIcon, ThemedParagraph } from "../../components/utilities";
import { useFilterContext } from "../../hooks/FilterContext";
import { NewMoneyFiltersModal } from "../administrationnewmoneyfilters";
import { NewMoneyCreateModal, NewMoneyGrid } from "./components";

const AdminDashboardHeaderTitle = styled.div`
    flex: 1;
    display: flex;
    font-size: 16px;

    & > span:not(:last-child) {
        margin-right: 1rem;
    }
`;

const AdminDashboardFiltersAppliedWarningMessage = styled(ThemedParagraph)`
    font-size: 12px !important;
`;

const PageHeaderTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: .75rem;
`;

const AdministrationNewMoney = () => {

    const [isFiltersShown, setIsFiltersShown] = useState(false);
    const [isCreateShown, setIsCreateShown] = useState(false);

    const {
        clearFilter,
        isFiltersApplied,
    } = useFilterContext();

    return <>
        <NewMoneyFiltersModal
            handleClose={() => setIsFiltersShown(false)}
            show={isFiltersShown}
        />
        <NewMoneyCreateModal
            handleClose={() => setIsCreateShown(false)}
            show={isCreateShown}
        />
        <PageHeader>
            <PageHeaderTop>
                <AdminDashboardHeaderTitle>
                    <span>New Money Administration</span>
                </AdminDashboardHeaderTitle>
            </PageHeaderTop>
        </PageHeader>
        <Main className="d-flex flex-column">
            <AdministrationDashboardPageSubHeader>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        {isFiltersApplied && <>
                            <ThemedIcon icon="fa-triangle-exclamation" size="2x" variant="warning" />
                            <AdminDashboardFiltersAppliedWarningMessage className="ms-2 text-muted">
                                Warning - Filters are currently being applied to your results.
                                <br />
                                <button className="anchor" onClick={clearFilter}>Click here</button> to clear your filters.
                            </AdminDashboardFiltersAppliedWarningMessage>
                        </>}
                    </div>
                    <div className="d-flex flex-fill justify-content-end align-items-center">
                        <ButtonGroup>
                            <Button variant="success" onClick={() => setIsCreateShown(true)}>
                                Create New Money
                            </Button>
                            <Button variant="primary" onClick={() => setIsFiltersShown(true)}>
                                Filter New Money
                            </Button>
                        </ButtonGroup>
                    </div>
                </div>
            </AdministrationDashboardPageSubHeader>
            <section className="px-4 my-3 h-100">
                <NewMoneyGrid openCreateModal={() => setIsCreateShown(true)} />
            </section>
        </Main>
    </>;
};


export default AdministrationNewMoney;
