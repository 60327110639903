import _ from "lodash"
import React from "react";
import { useAuth } from "react-oidc-context";

const ClientReportControlsContext = React.createContext();

export const allowAssignAuthorRoles = ["sys_admin_access", "report_assign_author"];

export const allowChangeReportCategoryRoles = ["sys_admin_access", "report_doc_management"];

export const allowChangeReportFeedbackRoles = ["sys_admin_access", "report_doc_management"];

export const allowChangeReportTypeRoles = ["sys_admin_access", "report_doc_management"];

export const useClientReportControls = () => React.useContext(ClientReportControlsContext);

export const ClientReportControlsProvider = ({ children }) => {
    const auth = useAuth();
    const { user: { profile: { roles } } } = auth;
    const [targetReport, setTargetReport] = React.useState(null);
    const { isStatusCompliant, isStatusTerminated } = targetReport || { isStatusCompliant: false, isStatusTerminated: false };
    const isReportEditable = isStatusCompliant === false && isStatusTerminated === false;

    const isAllowedToAssignAuthor = isReportEditable === true && _.some(roles, i => allowAssignAuthorRoles.includes(i));
    const isAllowedToChangeReportCategories = isReportEditable === true && _.some(roles, i => allowChangeReportCategoryRoles.includes(i));
    const isAllowedToChangeReportFeedback = isReportEditable === true && _.some(roles, i => allowChangeReportFeedbackRoles.includes(i));
    const isAllowedToChangeReportType = isReportEditable === true && _.some(roles, i => allowChangeReportTypeRoles.includes(i));
    
    return (
        <ClientReportControlsContext.Provider value={{
            isAllowedToAssignAuthor,
            isAllowedToChangeReportCategories,
            isAllowedToChangeReportFeedback,
            isAllowedToChangeReportType,
            isReportEditable,
            targetReport,
            setTargetReport
        }}>
            {children}
        </ClientReportControlsContext.Provider>
    );
};