import { useEffect } from "react";
import { useFileMailMutation, useGetMailMessageByIdQuery } from "../../../services/useremails";
import { usePersonalOutlookContext } from "../../../hooks/PersonalOutlookContext";
import { useParams } from "react-router-dom";

const useEmail = (mailId) => {
    const { folderId } = useParams();
    const { selectedMailUserId: userId } = usePersonalOutlookContext();
    const { data, isLoading, isFetching, isError, error, refetch } = useGetMailMessageByIdQuery({ userId, folderId, mailId });
    const [fileMailTrigger, fileMailResult] = useFileMailMutation();

    const fileMail = ({ masterAccountId, projectId }) => {
        return new Promise((resolve, reject) => {
            const request = { masterAccountId, projectId };
            return fileMailTrigger({ mailId, request, userId, folderId })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const reload = (e) => {
        if (e && typeof (e.preventDefault)) {
            e.preventDefault();
        }

        refetch();
    };

    useEffect(() => {

        if (data && data.isRead) return;

        // patch request to read the email

    }, [data]);

    return {
        data: data || {},
        isLoading: isLoading || isFetching,
        isError,
        error,
        fileMail,
        fileMailResult,
        reload
    };
};

export default useEmail;