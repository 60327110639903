import React from "react";
import FormSelect from "../FormSelect";
import ProfileIcon from "../../ProfileIcon";
import { components } from "react-select";
import { useFetchUsersQuery } from "../../../services/identity";

export const GenericUserSelectOption = (props) => {
    const { data: { data }, label } = props;
    if (!data) {
        return (
            <components.Option {...props}><span>{label}</span></components.Option>
        )
    }

    const { isOnline } = data;
    return (
        <components.Option {...props}>
            <div className="d-flex align-items-center">
                <div className="d-flex align-items-center justify-center">
                    <ProfileIcon user={data} isOnline={isOnline} showOnlineStatusIndicator={true} width={24} height={24} />
                </div>
                <p className="mx-3 my-0 lh-1">
                    <span>{label}</span>
                </p>
            </div>
        </components.Option>
    );
};

export const GenericUserSelectSingleValue = (props) => {
    const { data: { data, label } } = props;
    if (!data || data.isOnline === undefined) {
        return (
            <components.SingleValue {...props}><span>{label}</span></components.SingleValue>
        )
    }

    const { isOnline } = data;
    return (
        <components.SingleValue {...props}>
            <div className="d-flex align-items-center">
                <div className="d-flex align-items-center justify-center">
                    <ProfileIcon user={data} isOnline={isOnline} showOnlineStatusIndicator={true} width={24} height={24} />
                </div>
                <p className="mx-3 my-0 lh-1">
                    <span>{label}</span>
                </p>
            </div>
        </components.SingleValue>
    );
};

const GenericUserSelect = ({ options, divisionId = null, includeInactive = false, components, ...rest }) => {
    const { data, error, isError, isLoading, isFetching } = useFetchUsersQuery({ 
        division: divisionId, 
        includeInactive, 
    });
    
    const _map = (el) => {
        return {
            label: el.displayName,
            value: el.id,
            isDisabled: el.isActive === false || el.isVisible === false,
            data: el
        };
    };

    const dataOptions = React.useMemo(() => {
        if (!data || !data.results) {
            return [];
        }


        return [
            {
                label: 'Active Users',
                options: data.results.filter(el => el.isActive === true && el.isVisible === true).map(_map)
            },
            {
                label: 'Inactive Users',
                options: data.results.filter(el => includeInactive === true && (el.isActive === false || el.isVisible === false)).map(_map)
            }
        ];
    }, [ data ]);
    
    return <FormSelect
        options={dataOptions}
        isLoadingOptions={isLoading || isFetching}
        components={{
            ...components,
            Option: GenericUserSelectOption,
            SingleValue: GenericUserSelectSingleValue
        }}
        {...rest}
    />
};

export default GenericUserSelect;