import { useCallback, useEffect, useMemo, useState } from "react";
import GridWrapper from "../../components/GridWrapper";
import { FormCheck } from "../../components/forms";
import { Spinner } from "../../components/loaders";
import { AccordionBody, AccordionHeader, AccordionList, AccordionListItem, CenterArea, FiltersAppliedWarningMessage, Main, PageSubHeader, ThemedIcon } from "../../components/utilities";
import { useFilterContext } from "../../hooks/FilterContext";
import { useLazyCountAssetGroupsForAnalysisGridQuery } from "../../services/assets";
import { InvestmentCommitteeFundFiltersModal, InvestmentCommitteeFundFiltersModalButton } from "../icfundfilters";
import FundAnalysisGrid from "./components/FundAnalysisGrid";
import _ from "lodash";

const volatilityProfiles = [
    { label: "Cash", value: 0 },
    { label: "Low", value: 1 },
    { label: "Low To Moderate", value: 2 },
    { label: "Moderate", value: 3 },
    { label: "Moderate To High", value: 4 },
    { label: "High", value: 5 },
    { label: "Structured Product", value: 6 },
];

const FundAnalysis = ({ isVolatility = false }) => {
    const [isFiltersShown, setIsFiltersShown] = useState(false);
    const {
        clearFilter,
        filter,
        defaultFilters
    } = useFilterContext();

    const [countAssetGroupsTrigger] = useLazyCountAssetGroupsForAnalysisGridQuery();

    // Since this needs the same context as the management filters, filtersApplied needs to be specific to the just analysis ones
    const analystFilter = useMemo(() => ({
        assetName: filter.assetName,
        monitoring: filter.monitoring,
        standardPortfolio: filter.standardPortfolio,
        volatilityProfile: filter.volatilityProfile,
        assetClass: filter.assetClass,
        management: filter.management,
        region: filter.region,
        style: filter.style,
    }), [filter.assetClass, filter.assetName, filter.management, filter.monitoring, filter.region, filter.standardPortfolio, filter.style, filter.volatilityProfile]);

    const defaultAnalystFilter = useMemo(() => ({
        assetName: defaultFilters.assetName,
        monitoring: defaultFilters.monitoring,
        standardPortfolio: defaultFilters.standardPortfolio,
        volatilityProfile: defaultFilters.volatilityProfile,
        assetClass: defaultFilters.assetClass,
        management: defaultFilters.management,
        region: defaultFilters.region,
        style: defaultFilters.style,
    }), [defaultFilters.assetClass, defaultFilters.assetName, defaultFilters.management, defaultFilters.monitoring, defaultFilters.region, defaultFilters.standardPortfolio, defaultFilters.style, defaultFilters.volatilityProfile]);

    const isFiltersApplied = useMemo(() => !_.isEqual(analystFilter, defaultAnalystFilter), [analystFilter, defaultAnalystFilter]);

    // Store current grouping, open accordions, and sorting status in session storage
    const [groupByVolatility, setGroupByVolatility] = useState(true);
    const [groupBySector, setGroupBySector] = useState(false);

    const [openVolatilities, setOpenVolatilities] = useState([]);

    const [currentVolSort, setCurrentVolSort] = useState(null);
    const [currentRetSort, setCurrentRetSort] = useState(null);

    const currentSort = useMemo(() => isVolatility ? currentVolSort : currentRetSort,
        [isVolatility, currentVolSort, currentRetSort]);

    const setGroupByVolatilityInSessionStorage = (value) => {
        sessionStorage.setItem("groupByVolatility", value.toString());
        setGroupByVolatility(value);
    }

    const setGroupBySectorInSessionStorage = (value) => {
        sessionStorage.setItem("groupBySector", value.toString());
        setGroupBySector(value);
    }

    const setOpenVolatilitiesInSessionStorage = (value) => {
        sessionStorage.setItem("openVolatilities", JSON.stringify(value));
        setOpenVolatilities(value);
    }

    const setSortInSessionStorage = (field) => {
        var nextSort = getNextSort(field);
        sessionStorage.setItem((isVolatility ? "vol" : "ret") + "Sort", nextSort);
        isVolatility ? setCurrentVolSort(nextSort) : setCurrentRetSort(nextSort);
    }

    const getNextSort = useCallback((field) => field === currentSort
        ? `${field}+Desc`
        : field === currentSort + "+Desc"
            ? ""
            : field, [currentSort]);

    useEffect(() => {
        var previousGroupByVolatility = sessionStorage.getItem("groupByVolatility");
        var previousGroupBySector = sessionStorage.getItem("groupBySector");
        var previousOpenVolatilities = sessionStorage.getItem("openVolatilities");

        if (previousGroupByVolatility) {
            setGroupByVolatility(previousGroupByVolatility === "true");
        }

        if (previousGroupBySector) {
            setGroupBySector(previousGroupBySector === "true");
        }

        if (previousOpenVolatilities) {
            setOpenVolatilities(JSON.parse(previousOpenVolatilities));
        }
    }, []);

    // Filter out Volatilities where there are no matching funds
    const [isCountingAssetGroups, setIsCountingAssetGroups] = useState(false);
    const [filteredVolatilities, setFilteredVolatilities] = useState([]);

    useEffect(() => {
        const filterList = async () => {
            const filtered = [];
            for (const profile of volatilityProfiles) {
                const result = await countAssetGroupsTrigger({
                    volatility: profile.value,
                    filters: analystFilter
                });

                if (result.data !== 0) {
                    filtered.push(profile);
                }
            }
            setFilteredVolatilities(filtered);
        };

        setIsCountingAssetGroups(true);
        filterList().then(() => setIsCountingAssetGroups(false));
    }, [analystFilter, countAssetGroupsTrigger]);

    return <>
        <InvestmentCommitteeFundFiltersModal
            handleClose={() => setIsFiltersShown(false)}
            show={isFiltersShown}
        />
        <Main className="d-flex flex-column">
            <PageSubHeader>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        {isFiltersApplied && <>
                            <ThemedIcon icon="fa-triangle-exclamation" size="2x" variant="warning" />
                            <FiltersAppliedWarningMessage className="ms-2 text-muted">
                                Warning - Filters are currently being applied to your results.
                                <br />
                                <button className="anchor" onClick={clearFilter}>Click here</button> to clear your filters.
                            </FiltersAppliedWarningMessage>
                        </>}
                    </div>
                    <GridWrapper gridTemplateColumns={"1fr 1fr"} className="ps-3">
                        <FormCheck
                            label="Group By Volatility:"
                            labelFirst
                            disableAnimations
                            onChange={() => setGroupByVolatilityInSessionStorage(!groupByVolatility)}
                            isChecked={groupByVolatility}
                        />
                        <FormCheck
                            labelClassName="ms-2"
                            label="Group By Sector:"
                            labelFirst
                            disableAnimations
                            onChange={() => setGroupBySectorInSessionStorage(!groupBySector)}
                            isChecked={groupBySector}
                        />
                    </GridWrapper>
                    <div className="d-flex flex-fill justify-content-end align-items-center">
                        <InvestmentCommitteeFundFiltersModalButton
                            onClick={() => setIsFiltersShown(true)}
                        />
                    </div>
                </div>
            </PageSubHeader>
            <section className="px-4 my-3 h-100">
                {groupByVolatility
                    ? isCountingAssetGroups
                        ? <CenterArea>
                            <Spinner />
                        </CenterArea>
                        : filteredVolatilities?.length === 0
                            ? <CenterArea>
                                <p>There are no funds matching your filters in this Investment Committee</p>
                            </CenterArea>
                            : <AccordionList
                                className="d-grid"
                                activeKey={openVolatilities}
                                defaultActiveKey={null}
                                onSelect={(eventKeys, _) => setOpenVolatilitiesInSessionStorage(eventKeys)}
                                gap={1}
                                alwaysOpen
                            >
                                {filteredVolatilities?.map((profile, index) =>
                                    <AccordionListItem className="my-2" key={index} eventKey={index}>
                                        <AccordionHeader>
                                            {profile.label}
                                        </AccordionHeader>
                                        <AccordionBody>
                                            {openVolatilities.includes(index) ? <FundAnalysisGrid
                                                isVolatility={isVolatility}
                                                volatilityProfile={profile.value}
                                                groupBySector={groupBySector}
                                                currentSort={currentSort}
                                                sort={setSortInSessionStorage}
                                            /> : <Spinner />}
                                        </AccordionBody>
                                    </AccordionListItem>)}
                            </AccordionList>
                    : <FundAnalysisGrid
                        className="p-3"
                        isVolatility={isVolatility}
                        groupBySector={groupBySector}
                        currentSort={currentSort}
                        sort={setSortInSessionStorage}
                    />}
            </section>
        </Main>
    </>
}

export default FundAnalysis;