import { useEffect } from "react";
import { ButtonGroup, Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { Button } from "../../../components";
import { CurrencyInput, DateInput, FormInput, PercentageInput } from "../../../components/forms";
import { MasterAccountSelect } from "../../../components/forms/selects";
import useAdminNewMoney from "../hooks/useAdminNewMoney";

export const NewMoneyCreateModal = ({ show, handleClose }) => {
    const { makeTitle, createNewMoney, createApprovedNewMoney, isLoading } = useAdminNewMoney();

    const { control, watch, formState: { errors }, handleSubmit, reset, setValue } = useForm({
        defaultValues: {
            masterAccountId: null,
            masterAccountName: null,
            description: null,
            investmentAmount: 0,
            serviceFeePercent: 0,
            serviceFeeAmount: 0,
            newMoneyDate: null,
        }
    });

    const clearAndClose = () => {
        reset();
        handleClose();
    }

    const watchDescription = watch("description");
    const watchInvestmentAmount = watch("investmentAmount");
    const watchServicePercentAmount = watch("serviceFeePercent");
    const watchServiceFeeAmount = watch("serviceFeeAmount");
    const watchMasterAccountName = watch("masterAccountName");
    const watchNewMoneyDate = watch("newMoneyDate");

    const title = makeTitle(watchDescription, watchInvestmentAmount, watchServiceFeeAmount, watchMasterAccountName, watchNewMoneyDate);

    // Calculate fee amount based on investment and rate
    useEffect(() => {
        const feeAmount = Math.floor(watchInvestmentAmount * watchServicePercentAmount / 100)
        setValue("serviceFeeAmount", feeAmount);
    }, [setValue, watchInvestmentAmount, watchServicePercentAmount]);

    const onSubmit = (data) => {
        clearAndClose();
        createNewMoney(data);
    }

    const onSubmitAndApprove = (data) => {
        clearAndClose();
        createApprovedNewMoney(data);
    }

    return <Modal size="xl" show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
            <div>
                <h4>Register New Money</h4>
                <h5 className="mb-0">{title}</h5>
            </div>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmitAndApprove)}>
            <Modal.Body>
                <div className="row gx-2">
                    <div className="col-12 col-lg-5 col-xl-5">
                        <Controller
                            name="masterAccountId"
                            control={control}
                            // rules={{}} Master account not required
                            render={({ field: { ref, value, onChange, ...rest } }) => <MasterAccountSelect
                                label="Master Account"
                                value={value}
                                setValue={({ value, data: { accountName } }) => {
                                    setValue("masterAccountName", accountName);
                                    setValue("masterAccountId", value);
                                }}
                                errorMessage={errors?.masterAccountId?.message}
                                {...rest}
                            />}
                        />
                        {/* <MasterAccountSelect
                            label="Master Account"
                            value={newMoney.masterAccountId}
                            setValue={(value) => handleChange('masterAccountId', value)}
                        /> */}
                    </div>
                    <div className="col-12 col-lg-7 col-xl-7">
                        <Controller
                            name="description"
                            control={control}
                            rules={{ required: "Description is required." }}
                            render={({ field: { ref, value, onChange, ...rest } }) => <FormInput
                                label="Description"
                                value={value}
                                onChange={(e) => setValue("description", e.target.value)}
                                disableAnimations
                                errorMessage={errors?.description?.message}
                                {...rest}
                            />}
                        />
                        {/* <FormInput
                            label="Description"
                            onChange={(e) => handleChange('description', e.target.value)}
                            value={newMoney.description}
                            disableAnimations
                        /> */}
                    </div>
                    <div className="col-3 col-xl-3 mt-2">
                        <Controller
                            name="investmentAmount"
                            control={control}
                            rules={{ required: "Investment amount is required." }}
                            render={({ field: { ref, value, onChange, ...rest } }) => <CurrencyInput
                                label="Investment"
                                value={value}
                                onChange={(_, values) => setValue("investmentAmount", Math.floor(values.floatValue))}
                                disableAnimations
                                decimalScale={0}
                                fixedDecimalScale
                                errorMessage={errors?.investmentAmount?.message}
                                {...rest}
                            />}
                        />
                        {/* <CurrencyInput
                            label="Investment"
                            value={newMoney.investmentAmount}
                            onChange={(_, values) => handleChange('investmentAmount', Math.floor(values.floatValue))}
                            disableAnimations
                            decimalScale={0}
                            fixedDecimalScale
                        /> */}
                    </div>
                    <div className="col-2 col-xl-2 mt-2">
                        <Controller
                            name="serviceFeePercent"
                            control={control}
                            rules={{ required: "Service fee percentage is required." }}
                            render={({ field: { ref, value, onChange, ...rest } }) => <PercentageInput
                                label="Rate (%)"
                                value={value}
                                onChange={(_, values) => setValue("serviceFeePercent", values.floatValue)}
                                disableAnimations
                                decimalScale={2}
                                fixedDecimalScale
                                errorMessage={errors?.serviceFeePercent?.message}
                                {...rest}
                            />}
                        />
                        {/* <PercentageInput
                            label="Rate (%)"
                            value={newMoney.serviceFeePercent}
                            onChange={(_, values) => handleChange('serviceFeePercent', values.floatValue)}
                            disableAnimations
                            decimalScale={2}
                            fixedDecimalScale
                        /> */}
                    </div>
                    <div className="col-3 col-xl-3 mt-2">
                        <Controller
                            name="serviceFeeAmount"
                            control={control}
                            render={({ field: { ref, value, onChange, ...rest } }) => <CurrencyInput
                                label="Service Fee (£)"
                                value={value}
                                disableAnimations
                                disabled
                                decimalScale={0}
                                fixedDecimalScale
                                errorMessage={errors?.serviceFeeAmount?.message}
                                {...rest}
                            />}
                        />
                        {/* <CurrencyInput
                            label="Service Fee (£)"
                            value={newMoney.serviceFeeAmount}
                            // onChange={(_, values) => handleChange('serviceFeeAmount', values.floatValue)}
                            disableAnimations
                            decimalScale={0}
                            fixedDecimalScale
                            disabled
                        /> */}
                    </div>
                    <div className="col col-xl-3 mt-2">
                        <Controller
                            name="newMoneyDate"
                            control={control}
                            rules={{ required: "Date is required." }}
                            render={({ field: { ref, value, onBlur, onChange, ...rest } }) => <DateInput
                                label="Date"
                                value={value}
                                isClearable
                                onBlur={(newValue) => setValue("newMoneyDate", newValue)}
                                disableAnimations
                                errorMessage={errors?.newMoneyDate?.message}
                                {...rest}
                            />}
                        />
                        {/* <DateInput
                            label="Date"
                            value={newMoney.newMoneyDate}
                            onBlur={(newValue) => handleChange("newMoneyDate", newValue)}
                            disableAnimations
                        /> */}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <ButtonGroup>
                    <Button variant="danger" onClick={clearAndClose} disabled={isLoading}>Cancel</Button>
                    <Button variant="warning" onClick={handleSubmit(onSubmit)} disabled={isLoading}>Create</Button>
                    <Button variant="success" type='submit' disabled={isLoading}>Create and Approve</Button>
                </ButtonGroup>
            </Modal.Footer>
        </form>
    </Modal>
}