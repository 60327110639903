import { Modal } from "react-bootstrap";
import { Button, IconButton } from "../../../components";
import { CurrencyInput, FormInput, FormLabel, PercentageInput } from "../../../components/forms";
import { Spinner } from "../../../components/loaders";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import useNewMoney from "../hooks/useNewMoney";
import { useState } from "react";

const NewMoneyRow = ({ data, addRow, deleteRow, patchRow, className, index }) =>
    <div className={`row gx-2 ${className ?? ""}`} key={data?.newMoneyId ?? index}>
        <div className="col-2 col-sm-4 col-lg-6 col-xl-7">
            {data == null
                ? <FormLabel className="ps-1">Description</FormLabel>
                : <FormInput
                    onChange={(e) => patchRow('description', e.target.value)}
                    value={data.description}
                    disableAnimations
                />}
        </div>
        <div className="col">
            {data == null
                ? <FormLabel className="ps-1">Investment</FormLabel>
                : <CurrencyInput
                    onChange={(_, values) => patchRow('investmentAmount', Math.floor(values.floatValue))}
                    value={data.investmentAmount}
                    disableAnimations
                    decimalScale={0}
                    fixedDecimalScale
                />}
        </div>
        <div className="col">
            {data == null
                ? <FormLabel className="ps-1">Rate (%)</FormLabel>
                : <PercentageInput
                    onChange={(_, values) => patchRow('serviceFeePercent', values.floatValue)}
                    value={data.serviceFeePercent}
                    disableAnimations
                    decimalScale={2}
                    fixedDecimalScale
                />}
        </div>
        <div className="col">
            {data == null
                ? <FormLabel className="ps-1">Service Fee</FormLabel>
                : <CurrencyInput
                    value={data.serviceFeeAmount}
                    disabled
                    disableAnimations
                    decimalScale={0}
                    fixedDecimalScale
                />}
        </div>
        <div className="col-auto">
            <IconButton
                icon={data == null ? "circle-plus" : "fa-trash-can"}
                variant={data == null ? "success" : "danger"}
                onClick={data == null ? addRow : deleteRow}
            />
        </div>
    </div>;


const NewMoneyModal = ({ show, onHide }) => {
    const {
        newMoney,
        isFetching,
        isError,
        error,
        refetch,
        addRow,
        deleteRow,
        patchRow,
        cancelChanges,
        saveChanges,
        isSaving,
        // saveIsError,
        // saveError
    } = useNewMoney({ closeModal: onHide })

    return <Modal show={show} onHide={onHide} size="xl" backdrop="static" centered>
        <Modal.Header>
            <Modal.Title>Register Value of New Service Fee Paying Investment Inflows</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {isFetching
                ? <Spinner />
                : isError
                    ? <ErrorMessageWrapper>
                        <ErrorMessage
                            error={error}
                            message={"Could not fetch/add new money to this business sheet."}
                            retryCallback={refetch}
                        />
                    </ErrorMessageWrapper>
                    : newMoney?.length > 0
                        ? <>
                            {/* Header Row */}
                            <NewMoneyRow addRow={addRow} index={-1} />

                            {/* Data Rows */}
                            {newMoney?.map((row, index) => <NewMoneyRow
                                key={row.newMoneyId ?? index}
                                className="mt-1"
                                deleteRow={() => deleteRow(index)}
                                patchRow={(prop, newValue) => patchRow(index, prop, newValue)}
                                data={row}
                                index={index}
                            />)}
                        </>
                        : <p>No New Money is registered for this business sheet. Click <button className="anchor" onClick={addRow}>here</button> to add some.</p>}
        </Modal.Body>
        {!isError && <Modal.Footer>
            <Button variant="danger" onClick={cancelChanges} disabled={isSaving}>
                Cancel
            </Button>
            <Button variant="success" onClick={saveChanges} disabled={isSaving}>
                {isSaving ? 'Saving...' : 'Save Changes'}
            </Button>
        </Modal.Footer>}
    </Modal>;
}

const NewMoney = ({ newMoneySum }) => {
    const [show, setShow] = useState(false);
    const onHide = () => setShow(false);

    return <>
        <Button onClick={() => setShow(true)}>
            {newMoneySum > 0 ? `New Money: £${newMoneySum.toLocaleString()}` : "Register New Money"}
        </Button>
        <NewMoneyModal show={show} onHide={onHide} />
    </>;
}

export default NewMoney;