import _ from "lodash";
import React, { useMemo, useState } from "react";
import moment from "moment";
import Moment from "react-moment";
import Skeleton from "react-loading-skeleton";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import { ThemedParagraph } from "../../../components/utilities";
import { useClientContext } from "../../../hooks/ClientContext";
import { usePatchReminderTaskMutation } from "../../../services/reminders";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "../../../components";
import { CreateReminderModal } from "../../createreminderform";
import classNames from "classnames";
import { MessagePriorities } from "../../../helpers/constants";
import styled from "styled-components";
import { darken } from "polished";
import EditReminderModal from "./EditReminderModal";

const ReminderListItemWrapper = styled.div`
    &:hover {
        cursor: pointer;
        background-color: ${props => darken(props.theme.linkDarkenAmount, props.theme.light)};
    }
`;

const ReminderDeadlineDateCell = ({ deadlineDate }) => {

    var date = moment(deadlineDate);
    let format = "HH:mm DD/MM/YYYY";

    if (date.hours() === 0 && date.minutes() === 0) {
        format = "DD/MM/YYYY";
    }

    return <Moment
        className="mx-1"
        date={date}
        format={format}
    />;
};

const ReminderPriorityCell = ({ value, isInfoOnly }) => {

    if (!value || isInfoOnly) return null;;

    const className = [{
        'bg-priority-high text-priority-high': value === MessagePriorities.High,
        'bg-priority-medium text-priority-medium': value === MessagePriorities.Medium,
        'bg-priority-low text-priority-low': value === MessagePriorities.Low
    }];

    return <div className={classNames("badge py-1 px-2 mx-1", className)}>
        {{
            [MessagePriorities.Low]: "Low",
            [MessagePriorities.Medium]: "Medium",
            [MessagePriorities.High]: "High"
        }[value]}
    </div>;
};

export const ReminderDisplayRecord = ({ onClick, record, displayProject = false, fetchQuery }) => {
    const navigate = useNavigate();

    const handleOpenProject = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }
        navigate(`../projects?projectId=${record.projectId}`, { relative: 'path' });
    };

    return (
        <ReminderListItemWrapper className="border p-3 mb-2" onClick={() => onClick(record)}>
            {/* <div className="checkbox-container">
                <FormCheck
                    type="checkbox"
                    className={classNames({
                        'has-pointer': record.status === 0
                    })}
                    isChecked={record.status === 1}
                    disabled={record.status !== 0}
                    onChange={handleMarkedAsDone}
                />
            </div> */}
            <div className="d-flex align-items-center">
                <div className="me-3">{record.type}</div>
                <div className="me-3">{record.description}</div>
                {displayProject === true && (
                    <div className="font-size-sm">
                        <a href={`../projects?projectId=${record.projectId}`} onClick={handleOpenProject}>{record.project}</a>
                    </div>
                )}
                <div className="ms-auto d-flex align-items-center">
                    <ReminderPriorityCell value={record.priority} />
                    <ReminderDeadlineDateCell deadlineDate={record.deadlineDate} />
                </div>
            </div>
            {record.notes && record.notes.length > 0 && (
                <ThemedParagraph className="font-size-sm" variant="muted">{record.notes}</ThemedParagraph>
            )}

        </ReminderListItemWrapper>
    );
};

const ReminderDisplayList = ({ data, displayProject = false, error, isLoading, isFetching, projectId, originalArgs, refetch }) => {
    const { id: masterAccountId } = useClientContext();
    const { results } = data || { results: [] };
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [selectedReminder, setSelectedReminder] = useState(null);
    const displayResults = React.useMemo(() => _.orderBy(results, ['status', 'createdDate'], ['asc', 'desc']), [results]);

    const showEditModal = useMemo(() => selectedReminder !== null, [selectedReminder]);

    const onClickReminderRecord = (record) => {
        setSelectedReminder(record);
    }

    const onHideEditModal = () => setSelectedReminder(null);

    if (isLoading === true) {
        return <Skeleton height={32} count={3} />
    }

    if (error) {
        return (
            <ErrorMessageWrapper margin="0px auto" maxWidth="500px">
                <ErrorMessage error={error} retryCallback={() => refetch(originalArgs)} />
            </ErrorMessageWrapper>
        );
    }

    return (
        <React.Fragment>
            <CreateReminderModal
                show={showCreateModal}
                showUserSelect={true}
                masterAccountId={masterAccountId}
                projectId={projectId}
                onHide={() => setShowCreateModal(false)}
            />
            <EditReminderModal
                show={showEditModal}
                onHide={onHideEditModal}
                reminder={selectedReminder}
            />
            <div>
                <div className="d-flex justify-content-end mb-3">
                    <Button size={"sm"} variant="success" onClick={() => setShowCreateModal(true)}>
                        <FontAwesomeIcon icon="fa-plus" size={"sm"} /> Create new Event
                    </Button>
                </div>
                <div className="d-flex flex-column">
                    {(!results || results.length < 1) && (
                        <p className="m-0">There are currently no events available.</p>
                    )}
                    {results && results.length > 0 && displayResults.map((el, i) => (
                        <ReminderDisplayRecord
                            key={i}
                            record={el}
                            onClick={onClickReminderRecord}
                            displayProject={displayProject}
                            fetchQuery={originalArgs}
                        />
                    ))}
                </div>
            </div>
        </React.Fragment>

    );
};

export default ReminderDisplayList;