/**
 * NOTE: this is the new one that should superceed `PagedSelect.js`
 * 
 * Provides a way to wrap the extension package 'react-select-async-paginate' with out WP layout/design components
 */

import React, { useEffect, useId, useMemo, useRef, useState } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import { onTabPressSelect } from "../../helpers/select";
import FormGroup from "./FormGroup";
import FormInputGroup from "./FormInputGroup";
import FormLabel from "./FormLabel";
import { CalculateClassName, CalculateSelectStyles } from "./FormSelect";
import InputErrorMessage from "./InputErrorMessage";

export const CalculateValue = (ref, value, data, defaultOptions, isMulti) => {
    if (!value || (!data && !defaultOptions)) {
        return null;
    }

    const { results } = data || { results: Array.isArray(defaultOptions) ? defaultOptions : [] };
    const existingOptions = ref ? ref.props.options : [];
    const completeList = [...existingOptions, ...results].filter((v, i, s) => s.indexOf(v) === i);

    if (isMulti === true && Array.isArray(value)) {
        return completeList.filter(({ value: v }) => value.includes(v))
    }
    else if (isMulti === true && !value) {
        return [];
    }

    return completeList.find(({ value: v }) => v === value) ?? null;
};

const FormSelectPaged = ({
    additional = { page: 1, pageLimit: 25 },
    cacheKeyDisabled = false,
    cacheOptions = false,
    className,
    components = null,
    debounceTimeout = 350,
    defaultOptions = true,
    errorMessage = null,
    horizontal = false,
    isCreatable = false,
    id,
    idProperty = "id",
    isMulti = false,
    label,
    loadOptionsOnMenuOpen = true,
    onOptionsLoaded = null,
    onStartOptionsLoad = null,
    query,
    searchTermPropertyName = "searchTerm",
    setValue = () => console.warn('set value method has not been assigned.'),
    value,
    valueObject,
    ...rest
}) => {
    const defaultComponentId = useId();
    const componentRef = useRef();
    const componentId = id || defaultComponentId;
    const [error, setError] = useState(null);
    const [fetchKey, setFetchKey] = useState(new Date().valueOf());
    const [fetchOptions, { data }] = query();

    // NOTE: use the standard FormSelect calculation method instead of copy & paste the code...
    const currentClassName = useMemo(() => CalculateClassName(className, false, error, 'flex-fill'), [className, error]);
    const currentStyles = useMemo(() => CalculateSelectStyles(error), [error]);

    // For displaying the current value within the combo-box
    // NOTE: store this with Memo so the re-render doesn't keep running this method/calculation logic
    const currentValue = useMemo(() => valueObject ?? CalculateValue(componentRef.current, value, data, defaultOptions, isMulti), [componentRef, data, defaultOptions, value, valueObject]);

    /**
     * Triggers the lazy redux rtk query and fetches the paged data.
     * 
     * @param {*} search 
     * @param {*} prevOptions 
     * @returns 
     */
    const onLoadOptions = async (search, loadedOptions, { page, ...rest }) => {
        // build the query
        let query = { [searchTermPropertyName]: search, page, ...rest };
        
        // check if we want to cancel the onLoadOptions method...
        if (onStartOptionsLoad && typeof (onStartOptionsLoad) === 'function') {
            var shouldLoadOptions = await onStartOptionsLoad(loadedOptions, query);
            if (shouldLoadOptions === false) {
                // stops us from doing an uneccessary load!
                return {
                    options: [],
                    hasMore: false,
                    additional: {
                        page,
                        ...rest
                    }
                }
            }
        }

        // call our trigger from the redux query
        const response = await fetchOptions(query);
        const { data, error, isError } = response;

        // check for an error in fetching the data...
        if (isError === true || error) {
            setError(_ => error)
            return {
                options: [],
                hasMore: false,
                additional: {
                    page,
                    ...rest
                }
            }
        }

        // handle paging and returning the data
        const { pagination: { page: currentPage, totalPages }, results } = data;
        return {
            options: results,
            hasMore: currentPage < totalPages,
            additional: {
                page: page + 1,
                ...rest
            }
        }
    };

    /**
     * Method that is triggered on the onChange event of the Async Paginate
     * Select box.
     * @param {*} e 
     * @returns 
     */
    const onValueChanged = (e) => setValue(e);

    /**
     * Check when the error occurs outwith the component, sets the error state so that
     * the error message attached to this form input group is disabled below the combo-box.
     * 
     * The state can be changed out-with this component and will also be modified interall within
     * this component.
     */
    useEffect(() => {
        if (errorMessage) {
            setError(_ => errorMessage);
        }
        else {
            setError(_ => null);
        }
    }, [errorMessage]);

    /**
     * Check when the value is changed out-with this component. We want to check that
     * the value set exists in the list, otherwise we want to re-fetch the data within
     * the list to grab the value(s)
     * 
     * Uses the 'key' property in the select component, which when changed will trigger
     * the load options method to re-fetch the data..
     * 
     * NOTE: this might need a bit of work as when we get more than 1 page of data...
     */
    useEffect(() => {
        if (!value || cacheKeyDisabled === true)
            return;

        const { results } = data || { results: [] };

        let dataValue = typeof (value) === 'object'
            ? results.find(el => el.value === value[idProperty])
            : results.find(el => el.value === value);

        if (!dataValue) {
            var newKeyDate = new Date();
            setFetchKey(newKeyDate.valueOf());
        }
    }, [value, cacheKeyDisabled]);

    return <FormGroup className={className} horizontal={horizontal}>
        {label && <FormLabel htmlFor={componentId} horizontal={horizontal}>{label}</FormLabel>}
        <FormInputGroup className={"has-error"} horizontal={horizontal} hasLabel={label ? true : false}>
            <AsyncPaginate
                defaultOptions={defaultOptions}
                id={componentId}
                isMulti={isMulti}
                selectRef={componentRef}
                key={fetchKey}
                cacheOptions={cacheOptions}
                styles={currentStyles}
                className={currentClassName}
                classNamePrefix="Select"
                menuPosition={'fixed'}
                value={currentValue}
                loadOptions={onLoadOptions}
                loadOptionsOnMenuOpen={loadOptionsOnMenuOpen}
                reduceOptions={onOptionsLoaded}
                components={components}
                onChange={onValueChanged}
                additional={additional}
                debounceTimeout={debounceTimeout}
                tabSelectsValue={false}
                onKeyDown={(e) => e.key === 'Tab' && onTabPressSelect(e)}
                {...rest}
            />
            <InputErrorMessage error={error} allowRetry={false} />
        </FormInputGroup>
    </FormGroup>
};

export default FormSelectPaged;