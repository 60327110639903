import { components } from "react-select"
import styled from "styled-components"
import { useLazyFetchMasterAccountDocumentsForSlugQuery } from "../../../services/clientdocuments"
import FileDisplay from "../../FileDisplay"
import FormSelectPaged from "../FormSelectPaged"

const FileDisplayOption = styled(FileDisplay)`
    border: 0 !important;
    margin-bottom: 0 !important;
    padding: 0.25rem 0 !important;
`;

export const DocumentSelectOption = (props) => {
    const { data: { data } } = props;

    return <components.Option {...props}>
        <FileDisplayOption className="p-0 border-0 mb-0" file={{ name: data.fileName, size: data.fileSize }} fileDataIcon="file" onClearFile={null} />
    </components.Option>
}

const DocumentSelect = ({
    className = "flex-fill",
    masterAccountId,
    slug,
    horizontal = false,
    id,
    isMulti = false,
    errorMessage = null,
    label,
    value,
    setValue,
    ...rest
}) => {
    return <FormSelectPaged
        additional={{ masterAccountId, slug }}
        className={className}
        horizontal={horizontal}
        value={value}
        query={useLazyFetchMasterAccountDocumentsForSlugQuery}
        components={{ Option: DocumentSelectOption }}
        setValue={setValue}
        onOptionsLoaded={(_, results) => results}
        isMulti={isMulti}
        errorMessage={errorMessage}
        label={label}
        {...rest}
    />
}

export default DocumentSelect;