import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import MomentComponent from "react-moment";
import styled from "styled-components";
import { Button, IconButton } from "../../../components";
import {
    FormLabel,
    FormTextArea
} from "../../../components/forms";
import { CenterFormColumnComponent } from "../../../components/utilities";
import useOpenDocument from "../../../hooks/useOpenDocument";
import CreateReportModal from "../../createreportmodal";
import SelectReportModal from "./SelectReportModal";
import TargetDate from "./TargetDate";

const StatusDisplay = styled.span`
    font-size: 16px;
    font-weight: bold;
`;

const FollowUpReport = ({ review, onFieldChanged, select }) => {

    const { openDocument, isFetching: isOpening } = useOpenDocument();

    const [showCreateReport, setShowCreateReport] = useState(false);
    const handleCreateReportClose = () => setShowCreateReport(false);
    const handleOnReportCreated = (r) => {
        //Open Report?
    };

    const [showSelectReport, setShowSelectReport] = useState(false);
    const handleSelectReportClose = () => setShowSelectReport(false);
    const handleOnReportSelected = (report) => {
        return select(report);
        //Open Report?
    }

    return (
        <React.Fragment>
            <CreateReportModal
                show={showCreateReport}
                defaultProjectId={review?.projectId}
                handleClose={handleCreateReportClose}
                onReportCreated={handleOnReportCreated}
            />
            <SelectReportModal
                show={showSelectReport}
                handleClose={handleSelectReportClose}
                onReportSelected={handleOnReportSelected}
                reportType="Follow Up Report"
            />

            <div className="row mt-4">
                <div className="col-2">
                    <div className="row">
                        <FormLabel className="col">Follow Up Status</FormLabel>
                    </div>
                    <div className="row">
                        <StatusDisplay className="col">
                            {review.followUpReportStatusText}
                        </StatusDisplay>
                    </div>
                </div>

                <div className="col">
                    <FormTextArea
                        label="Comment"
                        value={review.followUpStatusComment}
                        onBlur={(value) =>
                            onFieldChanged("followUpStatusComment", value)
                        }
                        disabled={!review.followUpRequired}
                    />
                </div>

                <div className="col-2 align-items-end">
                    <FormLabel className="col">Follow Up Report</FormLabel>
                    {review.followUpReportId != null && review.followUpGraphId != null && (
                        <Button
                            variant="primary"
                            className="w-100"
                            onClick={() =>
                                openDocument(review.followUpGraphId)
                            }
                            disabled={!review.followUpRequired || isOpening}
                        >
                            Open
                        </Button>
                    )}
                    {review.followUpReportId == null && (
                        <Dropdown>
                            <Dropdown.Toggle
                                variant="success"
                                className="w-100"
                                disabled={!review.followUpRequired || review?.reviewStatus === 1 || review?.reviewStatus === 2}
                            >
                                Create/Select
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => setShowCreateReport(true)}>Create Report</Dropdown.Item>
                                <Dropdown.Item onClick={() => setShowSelectReport(true)}>Select Existing</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    )}
                </div>

                <div className="col-md-auto d-flex align-items-end mb-1">
                    <CenterFormColumnComponent>

                        <IconButton
                            className="col-auto"
                            icon={review.followUpRequired ? "fa-x" : "fa-check"}
                            variant={review.followUpRequired ? "danger" : "success"}
                            onClick={(_) =>
                                onFieldChanged(
                                    "followUpRequired",
                                    !review.followUpRequired
                                )
                            }
                        />
                    </CenterFormColumnComponent>
                </div>
            </div>

            <div className="row">
                <div className="col-3">
                    {review.followUpAuthor &&
                        review.followUpAuthorAssignedDate && (
                            <FormLabel>
                                Assigned to {review.followUpAuthor} on
                                <MomentComponent
                                    className="ps-1"
                                    format="DD/MM/YYYY HH:mm"
                                >
                                    {review.followUpAuthorAssignedDate}
                                </MomentComponent>
                            </FormLabel>
                        )}
                </div>

                <div className="col-6">
                    <div className="row">
                        <div className="col d-flex justify-content-start">
                            {review.followUpCompliantDate && (
                                <FormLabel>
                                    Compliant:
                                    <MomentComponent
                                        className="ps-1"
                                        format="DD/MM/YYYY HH:mm"
                                    >
                                        {review.followUpCompliantDate}
                                    </MomentComponent>
                                </FormLabel>
                            )}
                        </div>
                        <div className="col d-flex justify-content-start">
                            {review.followUpSentDate && (
                                <FormLabel>
                                    Sent:
                                    <MomentComponent
                                        className="ps-1"
                                        format="DD/MM/YYYY HH:mm"
                                    >
                                        {review.followUpSentDate}
                                    </MomentComponent>
                                </FormLabel>
                            )}
                        </div>
                    </div>
                </div>

                <div className="col-3 d-flex justify-content-end">
                    {review.followUpSentTargetDate && (
                        <TargetDate
                            date={review.followUpSentDate}
                            targetDate={review.followUpSentTargetDate}
                        />
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default FollowUpReport;
