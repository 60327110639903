import React from "react";
import FileUploader from "../../../components/FileUploader";
import FormGroup from "../../../components/forms/FormGroup";
import MasterAccountProjectPagedSelect from "./MasterAccountProjectSelect";
import { Controller } from "react-hook-form";
import { DelegatedAccessTypes, MessagePriorities } from "../../../helpers/constants";
import { MessageUsersSelect, MasterAccountSelect, UserDelegatesSelect } from "../../../components/forms/selects";
import { DateInput, FormCheck, FormInput, FormLabel, FormSelect, FormTextArea } from "../../../components/forms";

const CreateMessageForm = ({ attachmentsResponse, control, watch, formState: { errors }, setValue }) => {
    const masterAccount = watch("masterAccount");
    const isActionRequired = watch("isActionRequired");

    return <React.Fragment>
        <div className="row mb-3">
            <div className="col">
                <Controller
                    name="masterAccount"
                    control={control}
                    rules={({ required: false })}
                    render={({ field: { ref, value, onChange, ...rest } }) => (
                        <MasterAccountSelect
                            label="Master Account"
                            valueObject={value}
                            disableAnimations={true}
                            errorMessage={errors?.masterAccount?.message}
                            errorAllowRetry={false}
                            setValue={(e) => setValue('masterAccount', e)}
                            {...rest}
                        />
                    )}
                />
            </div>
            <div className="col">
                <Controller
                    name="subject"
                    control={control}
                    rules={({ required: false })}
                    render={({ field: { ref, value, onChange, ...rest } }) => (
                        <FormInput
                            label="Subject"
                            value={value}
                            onChange={e => setValue("subject", e.target.value)}
                            disableAnimations={true}
                            errorMessage={errors?.subject?.message}
                            errorAllowRetry={false}
                        />
                    )}
                />
            </div>
        </div>
        <div className="row mb-3">
            <div className="col">
                <Controller
                    name="project"
                    control={control}
                    rules={({ required: false })}
                    render={({ field: { ref, value, onChange, onBlur, ...rest } }) => (
                        <MasterAccountProjectPagedSelect
                            label="Project"
                            value={value}
                            disabled={!masterAccount}
                            masterAccountId={masterAccount?.value}
                            disableAnimations={true}
                            errorMessage={errors?.project?.message}
                            errorAllowRetry={false}
                            onChange={(e) => setValue('project', e)}
                            {...rest}
                        />
                    )}
                />
            </div>
            <div className="col">
                <Controller
                    name="messagePriority"
                    control={control}
                    rules={({ required: true })}
                    render={({ field: { ref, value, onChange, onBlur, ...rest } }) => (
                        <FormSelect
                            label="Priority"
                            defaultValue={value}
                            options={Object.keys(MessagePriorities).map((label) => ({ label, value: MessagePriorities[label] }))}
                            disableAnimations={true}
                            errorMessage={errors?.messagePriority?.message}
                            errorAllowRetry={false}
                            onChange={(e) => setValue('messagePriority', e.value)}
                            {...rest}
                        />
                    )}
                />
            </div>
            <div className={"col-auto d-flex align-items-end" + (isActionRequired ? "" : " pb-2")}>
                <p className="m-0">
                    <Controller
                        name="isActionRequired"
                        control={control}
                        rules={({ required: false })}
                        render={({ field: { ref, value, onChange, ...rest } }) => (
                            <FormCheck
                                label="Action Required?"
                                isChecked={value}
                                disableAnimations={true}
                                errorMessage={errors?.isActionRequired?.message}
                                errorAllowRetry={false}
                                onChange={(e) => setValue('isActionRequired', e)}
                                {...rest}
                            />
                        )}
                    />
                    {isActionRequired && <Controller 
                        name="isReceiptRequested"
                        control={control}
                        rules={({ required: false })}
                        render={({ field: { ref, value, onChange, ...rest } }) => (
                            <FormCheck
                                label="Notify when Done?"
                                isChecked={value}
                                disableAnimations={true}
                                errorMessage={errors?.isReceiptRequested?.message}
                                errorAllowRetry={false}
                                onChange={(e) => setValue('isReceiptRequested', e)}
                                {...rest}
                            />
                        )}
                    />}
                </p>
            </div>
            <div className="col">
                <Controller
                    name="deadlineDate"
                    control={control}
                    rules={({ required: false })}
                    render={({ field: { ref, value, onChange, onBlur, ...rest } }) => (
                        <DateInput
                            label="Deadline"
                            value={value}
                            disabled={!isActionRequired}
                            disableAnimations={true}
                            errorMessage={errors?.deadlineDate?.message}
                            errorAllowRetry={false}
                            onBlur={(e) => setValue('deadlineDate', e)}
                            {...rest}
                        />
                    )}
                />
            </div>
        </div>
        <div className="row mb-3">
            <div className="col-4">
                <Controller
                    name="senderId"
                    control={control}
                    rules={({ required: "Sender is required!" })}
                    render={({ field: { ref, value, onChange, ...rest } }) => (
                        <UserDelegatesSelect
                            label="Sender"
                            accessType={DelegatedAccessTypes.writeMessages}
                            defaultValue={value}
                            disableAnimations={true}
                            errorMessage={errors?.senderId?.message}
                            errorAllowRetry={false}
                            onChange={(e) => setValue('senderId', e.value)}
                            {...rest}
                        />
                    )}
                />
            </div>
            <div className="col">
                <Controller
                    name="recipientIds"
                    control={control}
                    rules={({ required: "Recipients are required!" })}
                    render={({ field: { ref, value, onChange, ...rest } }) => (
                        <MessageUsersSelect
                            label="Recipients"
                            masterAccountId={masterAccount?.value}
                            defaultValue={value}
                            disableAnimations={true}
                            errorAllowRetry={false}
                            errorMessage={errors?.recipientIds?.message}
                            isMulti
                            onChange={(e) => setValue('recipientIds', e.map(x => x.value))}
                            {...rest}
                        />
                    )}
                />
            </div>
        </div>
        <div className="row mb-2">
            <Controller
                name="message"
                control={control}
                rules={({ required: "Message is required!" })}
                render={({ field: { ref, value, onChange, ...rest } }) => (
                    <FormTextArea
                        label="Message"
                        value={value}
                        disableAnimations={true}
                        errorAllowRetry={false}
                        errorMessage={errors?.message?.message}
                        onChange={(e) => setValue('message', e.target.value)}
                        minRows={3}
                        {...rest}
                    />
                )}
            />
        </div>
        <div className="row mb-2">
            <Controller
                name="attachments"
                control={control}
                rules={{ required: false }}
                render={({ field: { ref, value, onChange, onBlur, ...rest } }) => (
                    <FormGroup>
                        <FormLabel>Attachments</FormLabel>
                        <FileUploader 
                            onFilesChanged={({ files, info }) => {
                                setValue("attachments", files);
                                setValue("attachmentsInfo", info);
                            }}
                        />
                    </FormGroup>
                )}
            />
        </div>
    </React.Fragment>;
};

export default CreateMessageForm;