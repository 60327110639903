import { wealthProApi } from '../../redux/api';
import * as jsonpatch from 'fast-json-patch';
import {socket} from "../../redux/socket";
import { applyPatch } from 'fast-json-patch';

const clientReviewApiEndpoints = wealthProApi
    .enhanceEndpoints({
        addTagTypes: ["reviews", "review"],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            fetchClientReviews: builder.query({
                query: ({ masterAccountId, page, limit }) =>
                    `api/review/MasterAccounts/${masterAccountId}/reviews?page=${page}&limit=${limit}`
            }),
            fetchClientReview: builder.query({
                query: ({ reviewId }) =>
                    `api/review/Review/GetReview/${reviewId}`,
                    async onCacheEntryAdded({ reviewId }, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
                        try {
                            socket.emit('subscribe-review', { room: reviewId.replace(/-/gi, '') });
                            await cacheDataLoaded;

                            socket.on('on-review-update', (json) => {
                                const review = JSON.parse(json);
                                
                                updateCachedData((data) => {
                                    return {
                                        ...data, 
                                        ...review
                                    };
                                })
                            });

                        }
                        catch {
                            console.log("Houston, we have a problem.");
                        }
                        await cacheEntryRemoved;
                        socket.emit('unsubscribe-review', { room: reviewId.replace(/-/gi, '') });
                        socket.off('on-review-update');
                    }
            }),
            patchClientReview: builder.mutation({
                query: ({ reviewId, operations }) => ({
                    url: `api/review/Review/${reviewId}`,
                    method: "PATCH",
                    body: operations,
                }),
                async onQueryStarted({ reviewId, operations }, { dispatch, queryFulfilled }) {
                    try
                    {
                        const { data: review } = await queryFulfilled;
                        const reviewQuery =  dispatch( wealthProApi.util.updateQueryData('fetchClientReview', { reviewId }, (data) => { 
                            return review;
                        }));

                        const detailedQuery = dispatch(wealthProApi.util.updateQueryData('detailed', { }, (data) => {
                            const { results } = data || { results: [] };
                            var pagedRecord = results.find(el => el.reviewId === reviewId);
                            if (pagedRecord) {
                                applyPatch(pagedRecord, operations, true);
                                pagedRecord.assignedToValuationUser = review.assignedToValuationUser;
                                pagedRecord.assignedToDrawdownUser = review.assignedToDrawdownUser;
                                pagedRecord.assignedToAppointmentUser = review.assignedToAppointmentUser;
                                pagedRecord.preReviewDocumentAuthor = review.preReviewDocumentAuthor;
                                pagedRecord.followUpAuthor = review.followUpAuthor;
                            }
                        }));
                    
                    }
                    catch(err) {
                        // console.log(err);
                        // reviewQuery.undo();
                        // detailedQuery.undo();
                    }
                },
            }),
            rearrangeClientAppointment: builder.mutation({
                query: ({ reviewId }) => ({
                    url: `api/review/review/rearrange/${reviewId}`,
                    method: "POST",
                    body: {},
                }),
                // invalidatesTags: ['review']
                async onQueryStarted(
                    { reviewId },
                    { dispatch, queryFulfilled }
                ) {
                    try {
                        var result = await queryFulfilled;
                        dispatch(
                            wealthProApi.util.updateQueryData(
                                "fetchClientReview",
                                { reviewId },
                                (review) => {
                                    console.log(result);
                                    return { ...review, ...result.data };
                                }
                            )
                        );
                    } catch {}
                },
            }),
            createReviewForMasterAccount: builder.mutation({
                query: ({ masterAccountId }) => ({
                    url: `api/review/review/${masterAccountId}`,
                    method: "POST",
                    body: {},
                }),
                invalidatesTags: ["reviews", "review"],
            }),
            uploadDrawDownEvidence: builder.mutation({
                query: ({ reviewId, request }) => ({
                    url: `api/review/review/${reviewId}/attachments`,
                    method: 'POST',
                    body: request
                }),
                async onQueryStarted({ reviewId }, { dispatch, queryFulfilled }) {
                    try {
                        const { data } = await queryFulfilled;

                        dispatch(
                            wealthProApi.util.updateQueryData(
                                'fetchClientReview',
                                { reviewId },
                                (results) => {
                                    results.drawdownEvidenceDocuments = [...results.drawdownEvidenceDocuments, ...data];
                                }
                            )
                        );

                    }
                    catch { }
                }
            })
        }),
    });

export const {
    useFetchClientReviewsQuery,
    useFetchClientReviewQuery,
    usePatchClientReviewMutation,
    useRearrangeClientAppointmentMutation,
    useCreateReviewForMasterAccountMutation,
    useUploadDrawDownEvidenceMutation,
} = clientReviewApiEndpoints;