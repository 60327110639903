import { useCallback, useState } from "react";
import { useClientContext } from "../../../hooks/ClientContext";
import { useLazyFetchFactFindPdfQuery } from "../../../services/clientfactfind";

const useFactFindPdf = () => {
    const { id: masterAccountId } = useClientContext();

    const [fetchPdfTrigger, { isFetching: isFetchingPdf, error: pdfError }] = useLazyFetchFactFindPdfQuery();
    const [pdfData, setPdfData] = useState(null);
    const [showPdfModal, setShowPdfModal] = useState(false);

    const fetchPdf = useCallback(() => fetchPdfTrigger({ masterAccountId }).unwrap(), [fetchPdfTrigger, masterAccountId]);

    const showPdf = useCallback(() => {
        setShowPdfModal(true);
        fetchPdf().then(res => {
            setPdfData(res.data);
        }, err => setPdfData(err.data));
    }, [fetchPdf]);

    const hidePdf = useCallback(() => {
        setPdfData(null);
        setShowPdfModal(false);
    }, []);

    return {
        showPdfModal,
        pdfData,
        isFetchingPdf,
        pdfError,
        showPdf,
        hidePdf,
        fetchPdf,
    };
}

export default useFactFindPdf;