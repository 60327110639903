import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { Button } from "../../../components";
import { DateTimeInput, FormInput, FormTextArea } from "../../../components/forms";
import { MasterAccountSelect, ReminderTypesSelect, UserDelegatesSelect } from "../../../components/forms/selects";
import { FileUploadModal } from "../../../components/modals";
import { AttachmentsToggle } from "../../../components/utilities";
import { DelegatedAccessTypes } from "../../../helpers/constants";
import MasterAccountProjectPagedSelect from "./MasterAccountProjectSelect";
import { patchReplace } from "../../../helpers/patchDoc";
import Skeleton from "react-loading-skeleton";
import useReminder from "../hooks/useReminder";

const EditPersonalReminderModalLoadingPanel = () => {

    const skeletonHeight = 48;

    return <React.Fragment>
        <div className="row mb-4">
            <div className="col">
                <Skeleton height={skeletonHeight} />
            </div>
            <div className="col">
                <Skeleton height={skeletonHeight} />
            </div>
        </div>
        <div className="row mb-4">
            <div className="col">
                <Skeleton height={skeletonHeight} />
            </div>
            <div className="col">
                <Skeleton height={skeletonHeight} />
            </div>
        </div>
        <div className="row mb-4">
            <div className="col">
                <Skeleton height={skeletonHeight} />
            </div>
            <div className="col">
                <Skeleton height={skeletonHeight} />
            </div>
            <div className="col-1">
                <Skeleton height={skeletonHeight} />
            </div>
        </div>
        <div className="row">
            <div className="col">
                <Skeleton height={skeletonHeight * 2.5} />
            </div>
        </div>
    </React.Fragment>

};

const EditReminderModal = ({ onHide, reminder, show }) => {

    const [showUploadFiles, setShowUploadFiles] = useState(false);

    const {
        id,
        description,
        notes,
        deadlineDate,
        // priority,
        appointmentId,
        projectId,
        project,
        userId,
        typeId,
        type,
        isTypeVisible,
        masterAccountId,
        masterAccountName,
        attachments
    } = reminder || {};

    const {
        patchReminder,
        patchReminderByOperations,
        uploadAttachment,
        uploadFilesResult
    } = useReminder(id);

    const { isLoading: isUploading, data: uploadedAttachments, ...uploadResult } = uploadFilesResult || {};

    const handleUpload = (files, fileInfo) => {
        toast.promise(uploadAttachment(files, fileInfo, masterAccountId), {
            loading: "Uploading attachment...",
            error: (err) => err?.data?.message ?? "Unable to upload attachment.",
            success: "Attachments uploaded!"
        }).then(() => {
            setShowUploadFiles(false);
        });
    };

    return (
        <React.Fragment>
            <Modal centered backdrop="static" size="lg" show={show} onHide={onHide}>
                <Modal.Header>{description}</Modal.Header>
                <Modal.Body>
                    {<>
                        <div className="row mb-3">
                            <div className="col">
                                {isTypeVisible
                                    ? <ReminderTypesSelect
                                        defaultValue={typeId}
                                        onBlur={(value) => patchReminder("typeId", value.value)}
                                        label="Type"
                                    />
                                    : <FormInput
                                        label="Type"
                                        value={type}
                                        readOnly={true}
                                    />}
                            </div>
                            <div className="col">
                                <UserDelegatesSelect
                                    accessType={DelegatedAccessTypes.writeReminders}
                                    label="User"
                                    defaultValue={userId}
                                    onChange={({ value }) => patchReminder("userId", value)}
                                />

                            </div>
                        </div>
                        {/* <div className="row mb-3">
                            <div className="col">
                                <MasterAccountSelect
                                    isDisabled
                                    label={"Account"}
                                    valueObject={{ label: masterAccountName, value: masterAccountId }}
                                    setValue={(selection) => {
                                        patchReminderByOperations([
                                            patchReplace("masterAccountId", selection.value),
                                            patchReplace("masterAccountName", selection.label),
                                            patchReplace("projectId", null),
                                        ]);
                                    }}
                                />
                            </div>
                            <div className="col">
                                <MasterAccountProjectPagedSelect
                                    label="Project"
                                    disabled={true}
                                    masterAccountId={masterAccountId}
                                    value={projectId}
                                    onBlur={({ value }) => patchReminder("projectId", value)}
                                />
                            </div>
                        </div> */}
                        <div className="row mb-3">
                            <div className="col">
                                <FormInput
                                    value={description}
                                    onBlur={(value) => patchReminder("description", value)}
                                    label="Description"
                                />
                            </div>
                            <div className="col">
                                <div className="row">
                                    <div className="col">
                                        <DateTimeInput
                                            value={deadlineDate}
                                            isClearable
                                            label={"Date"}
                                            onBlur={(value) => patchReminder("deadlineDate", value)}
                                        />
                                    </div>
                                    {/* <div className="col">
                                <FormSelect
                                    label="Priority"
                                    defaultValue={priority}
                                    options={Object.keys(MessagePriorities).map(label => ({ label, value: MessagePriorities[label] }))}
                                    onChange={({ value }) => patchReminder("priority", value)}
                                />
                            </div> */}
                                    <div className="col-auto mt-auto">
                                        <AttachmentsToggle
                                            attachments={attachments}
                                            isCreatable
                                            onUploadFn={() => setShowUploadFiles(true)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <FormTextArea
                                label="Notes"
                                value={notes}
                                onBlur={value => patchReminder("notes", value)}
                                minRows={3}
                            />
                        </div>
                    </>}
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-end">
                    <Button variant="light" onClick={onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
            <FileUploadModal
                handleConfirm={handleUpload}
                uploadProps={{ masterAccountId, projectId }}
                title="Upload Attachments"
                handleClose={() => setShowUploadFiles(false)}
                show={showUploadFiles}
                isUploadingFile={isUploading}
            />
        </React.Fragment>
    )
};

export default EditReminderModal;