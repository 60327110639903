import React from "react";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import { VirtualTable } from "../../../components/tables";
import { ThemedTableHeader } from "../../../components/tables/StandardTable";
import { Main } from "../../../components/utilities";
import useAdminNewMoney from "../hooks/useAdminNewMoney";
import { NewMoneyTableRow } from "./NewMoneyTableRow";
import { useFilterContext } from "../../../hooks/FilterContext";

export const NewMoneyGrid = ({ openCreateModal }) => {
    const {
        results,
        totalCount,
        columns,
        isError,
        error,
        clear,
        isClearing,
        isRowLoaded,
        loadMore,
    } = useAdminNewMoney();

    const { isFiltersApplied } = useFilterContext();

    if (isError && !isClearing)
        return <ErrorMessageWrapper>
            <ErrorMessage error={error} retryCallback={clear} />
        </ErrorMessageWrapper>
    
    if (totalCount === 0)
        return <Main>
            <div className="p-3">
                No new money found{isFiltersApplied ? " for the selected filters." : <>. Click <button className="anchor" onClick={openCreateModal}>here</button> to register.</>}
            </div>
        </Main>
    
    return (
        <VirtualTable
            enableScrollTracking={false}
            loadMoreItems={loadMore}
            row={(props) => <NewMoneyTableRow columns={columns} isClearing={isClearing} {...props} />}
            itemSize={42}
            itemCount={results.length === totalCount ? totalCount : results.length + 10}
            itemData={[...results]}
            isItemLoaded={isRowLoaded}
            header={<thead>
                <tr>
                    {columns.map((col, key) => col.headerRenderer
                        ? col.headerRenderer({ ...col, key })
                        : <ThemedTableHeader key={key} className={col.headerClassName ?? ""}>
                            {col.label}
                        </ThemedTableHeader>)}
                </tr>
            </thead>}
        />
    );
};