import React from "react";
import FormSelect from "../FormSelect";
import { useFetchReportTypesListQuery } from "../../../services/clientreports";

const ReportTypesSelect = ({ options, ...rest }) => {
    const { data, error, isError, isLoading, isFetching } = useFetchReportTypesListQuery({ });
    const { results } = data || { results: [] }
    
    return <FormSelect
        options={results}
        isLoadingOptions={isLoading || isFetching}
        {...rest}
    />
};

export default ReportTypesSelect;