import React, { useContext, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useLazyFetchMasterAccountProjectsListQuery } from "../services/clientprojects";

const ClientProjectContext = React.createContext();

export const useClientProjectContext = () => {
    return useContext(ClientProjectContext);
};

export const ClientProjectProvider = ({ children }) => {
    const { masterAccountId } = useParams();
    const [searchParams] = useSearchParams();
    const [cacheKey, setCacheKey] = useState(new Date().valueOf());
    const [selectedValue, setSelectedValue] = useState(null);
    const [pageLimit, setPageLimit] = useState(25);
    const [load, { data, isLoading, isUninitialized, ...queryResults }] = useLazyFetchMasterAccountProjectsListQuery();
    const { results, pagination: { page, totalCount, totalPages } } = data || { results: [], pagination: { page: 1, totalCount: 0, totalPages: 0 } }
    const hasMore = page < totalPages;

    const clear = () => {
        if (!masterAccountId) {
            return;
        }

        const newCacheKey = new Date().valueOf();
        load({
            cacheKey: newCacheKey,
            masterAccountId,
            searchText: "",
            page: 1,
            limit: pageLimit
        }).unwrap().then(_ => setCacheKey(newCacheKey))
    };

    const nextPage = () => {
        if (page >= totalPages) {
            return new Promise((resolve) => resolve(false));
        }
        return load({
            cacheKey,
            masterAccountId,
            searchText: "",
            page: page + 1,
            limit: pageLimit
        });
    };

    const previousPage = () => {
        if (page <= 1) {
            return new Promise((resolve) => resolve(false));
        }
        return load({
            cacheKey,
            masterAccountId,
            searchText: "",
            page: page - 1,
            limit: pageLimit
        });
    };

    useEffect(clear, [masterAccountId]);

    useEffect(() => {
        if (isUninitialized || isLoading || selectedValue) {
            return;
        }

        let defaultProjectId = searchParams.get('projectId');
        if (!defaultProjectId) {
            setSelectedValue(null);
            return;
        }

        let defaultProject = results.find(el => el.value === defaultProjectId);
        if (!defaultProject) {
            // If the selected project was not in the list loaded, fetch it individually
            setSelectedValue({ projectId: defaultProjectId });
            return;
        }

        setSelectedValue(defaultProject['data']);
    }, [searchParams, isLoading, isUninitialized, selectedValue]);

    return <ClientProjectContext.Provider value={{
        clear,
        data: results,
        hasMore,
        masterAccountId,
        nextPage,
        page,
        pageLimit,
        previousPage,
        selectedValue,
        setPageLimit,
        setSelectedValue,
        totalCount,
        totalPages,
        isLoading,
        isUninitialized,
        ...queryResults
    }}>
        {children}
    </ClientProjectContext.Provider>
};