import _ from "lodash";
import React from "react";
import classNames from "classnames";
import ClientReportTableRow from "./components/ClientReportTableRow";
import ClientReportsHistoryModal from "../clientreportshistorymodal";
import { VirtualTable } from "../../components/tables";
import { TableRecordCountDisplayFooter } from "../../components/tables/VirtualTable";
import { EditClientAuthorModal } from "../clientreportseditmodal";
import { useClientReportsList } from "../../hooks/ClientReportContext";
import { StandardTableSortableHeader } from "../../components/tables/StandardTable";
import { useEditReport } from "../clientreportseditmodal/hooks/EditReportContext";
import { useReportHistory } from "../clientreportshistorymodal/hooks/ReportHistoryContext";

const ClientReportsTableHeader = ({ children, property, className }) => {
    const { sort, sortTable } = useClientReportsList();
    const sortProperties = sort.split(',');
    const isHeaderSorted = _.some(sortProperties, i => i.includes(property));
    const headerDirection = isHeaderSorted && _.some(sortProperties, i => i.toLowerCase().includes('+desc')) ? "desc" : null;

    const handleClick = () => sortTable(property);

    return (
        <StandardTableSortableHeader
            className={classNames(className)}
            active={isHeaderSorted}
            direction={headerDirection}
            onClick={handleClick}
            isSticky={true}
        >
            {children}
        </StandardTableSortableHeader>
    );
};

const ClientReportsTable = () => {
    const { 
        clear: refreshTable,
        data,
        error,
        isNextPageLoading,
        isNewRecords,
        isRowLoaded,
        loadMore,
        newRecordsSinceCache,
        sort,
        sortTable,
        totalCount
    } = useClientReportsList();
    const { clear: clearSelectedReport, isLoading, isModalActive, report, saveChanges } = useEditReport();
    const { clear: clearSelectedHistoryReport, isModalActive: isReportHistoryShown, report: selectedHistoryReport } = useReportHistory();
    const isNewRecordsDisplayed = isNewRecords && !isLoading && !isNextPageLoading;

    return (
        <React.Fragment>
            <VirtualTable 
                enableScrollTracking={false}
                row={ClientReportTableRow}
                header={(
                    <thead>
                        <tr>
                            <ClientReportsTableHeader property="reportName" sort={sort} sortTable={sortTable}>Report Name</ClientReportsTableHeader>
                            <ClientReportsTableHeader property="projectName" sort={sort} sortTable={sortTable}>Project</ClientReportsTableHeader>
                            <ClientReportsTableHeader property="status" sort={sort} sortTable={sortTable}>Status</ClientReportsTableHeader>
                            <ClientReportsTableHeader property="authorName" sort={sort} sortTable={sortTable}>Author</ClientReportsTableHeader>
                            <ClientReportsTableHeader className="text-center" property="efficacy" sort={sort} sortTable={sortTable}>Quality</ClientReportsTableHeader>
                            <ClientReportsTableHeader property="complianceOfficerName" sort={sort} sortTable={sortTable}>Compliance</ClientReportsTableHeader>
                            <ClientReportsTableHeader property="compliantDate" sort={sort} sortTable={sortTable}>Compliant</ClientReportsTableHeader>
                            <ClientReportsTableHeader property="sentDate" sort={sort} sortTable={sortTable}>Sent</ClientReportsTableHeader>
                            <ClientReportsTableHeader property="createdDate" sort={sort} sortTable={sortTable}>Created</ClientReportsTableHeader>
                            <th className="sticky-header">&nbsp;</th>
                        </tr>
                    </thead>
                )}
                footer={
                    <TableRecordCountDisplayFooter 
                        totalCount={totalCount}
                        isNewRecordsDisplayed={isNewRecordsDisplayed}
                        newRecordsSinceCache={newRecordsSinceCache}
                        reload={refreshTable}
                    />
                }
                error={error}
                isLoading={isNextPageLoading}
                isItemLoaded={isRowLoaded}
                loadMoreItems={loadMore}
                itemSize={50}
                itemCount={totalCount}
                itemData={data}
            />
            <ClientReportsHistoryModal 
                report={selectedHistoryReport}
                show={isReportHistoryShown}
                handleClose={clearSelectedHistoryReport}
            />
            <EditClientAuthorModal 
                report={report}
                show={isModalActive}
                isLoading={isLoading}
                handleSave={(ops) => saveChanges(ops).then(clearSelectedReport)}
                handleClose={clearSelectedReport}
            />
        </React.Fragment>
    );
}

export default ClientReportsTable;