import { useState } from "react";
import { ButtonGroup, Modal } from "react-bootstrap";
import { Button } from "../../../components";
import { CurrencyInput, DateInput, FormInput, PercentageInput } from "../../../components/forms";

const formatCurrency = (value) => value.toLocaleString({ style: "currency", currency: "GBP", maximumFractionDigits: 0 });

// Build a title for the modal based on the new money details
export const makeTitle = (description, investmentAmount, feeAmount, masterAccountName, newMoneyDate) => {
    if (description) return description;

    const start = investmentAmount > 0
        ? `£${formatCurrency(investmentAmount)} (£${formatCurrency(feeAmount)}) `
        : "";

    const middle = masterAccountName ? ` for ${masterAccountName}` : "";

    // Want it to be "for {masterAccountName} (date) if there is no other info, otherwise "for {masterAccountName} on {date}"
    const end = newMoneyDate
        ? investmentAmount > 0
            ? ` on ${new Date(newMoneyDate).toLocaleDateString()}`
            : ` (${new Date(newMoneyDate).toLocaleDateString()})`
        : "";

    return start + middle + end;
}

export const NewMoneyApproveModal = ({ show, onHide, approve, cancel, existingNewMoney, disabled = false }) => {
    const [updated, setUpdated] = useState(existingNewMoney);

    const handleChange = (property, newValue) => setUpdated({ ...updated, [property]: newValue });

    const title = makeTitle(updated.description, updated.investmentAmount, updated.serviceFeeAmount, updated.masterAccountName, updated.newMoneyDate);

    return <Modal size="xl" show={show} onHide={onHide} centered backdrop="static">
        <Modal.Header closeButton>
            <div>
                <h4>Confirm New Money</h4>
                <h5 className="mb-0">{title}</h5>
            </div>
        </Modal.Header>
        <Modal.Body>
            <div className="row gx-2">
                <div className="col-12 col-xl">
                    <FormInput
                        label="Description"
                        onChange={(e) => handleChange('description', e.target.value)}
                        value={updated.description}
                        disableAnimations
                        disabled={disabled}
                    />
                </div>
                <div className="col-3 col-xl-2 mt-2 mt-xl-0">
                    <CurrencyInput
                        label="Investment"
                        value={updated.investmentAmount}
                        onChange={(_, values) => handleChange('investmentAmount', Math.floor(values.floatValue))}
                        disableAnimations
                        decimalScale={0}
                        fixedDecimalScale
                        disabled={disabled}
                    />
                </div>
                <div className="col-2 col-xl-1 mt-2 mt-xl-0">
                    <PercentageInput
                        label="Rate (%)"
                        value={updated.serviceFeePercent}
                        onChange={(_, values) => handleChange('serviceFeePercent', values.floatValue)}
                        disableAnimations
                        decimalScale={2}
                        fixedDecimalScale
                        disabled={disabled}
                    />
                </div>
                <div className="col-3 col-xl-2 mt-2 mt-xl-0">
                    <CurrencyInput
                        label="Service Fee (£)"
                        value={updated.serviceFeeAmount}
                        onChange={(_, values) => handleChange('serviceFeeAmount', values.floatValue)}
                        disabled={disabled}
                        disableAnimations
                        decimalScale={0}
                        fixedDecimalScale
                    />
                </div>
                <div className="col col-xl-2 mt-2 mt-xl-0">
                    <DateInput
                        label="Date"
                        value={updated.newMoneyDate}
                        onBlur={(newValue) => handleChange("newMoneyDate", newValue)}
                        // useOnBlurForDateChanged={false}
                        // onChange={(newValue) => handleChange("newMoneyDate", newValue)}
                        disableAnimations
                        disabled={disabled}
                    />
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <ButtonGroup>
                <Button variant="danger" onClick={onHide} disabled={disabled}>Cancel Changes</Button>
                <Button variant="warning" onClick={() => cancel()} disabled={disabled}>Save & Mark as Not Taken Up</Button>
                <Button variant="success" onClick={() => approve(updated)} disabled={disabled}>Save & Approve</Button>
            </ButtonGroup>
        </Modal.Footer>
    </Modal>
}