import { useEffect, useState } from "react";
import { useLazySearchApplicationsQuery, useSearchApplicationsQuery } from "../../../services/headofficeadmin";
import { useFilterContext } from "../../../hooks/FilterContext";
import { StandardTableSortableHeader } from "../../../components/tables/StandardTable";

const useApplications = (initSort = "createdDate+DESC") => {

    const { filter } = useFilterContext();
    const [searchTrigger, { data, isError, error, isUninitialized }] = useLazySearchApplicationsQuery();
    const { pagination, results } = data || { results: [], pagination: { totalCount: 100, page: 0, pageSize: 20, totalPages: null } };
    const { totalCount, page, pageSize: limit, totalPages } = pagination;
    const [isClearing, setIsClearing] = useState(false);
    const [sort, setSort] = useState(initSort);
    const [sortProperty, direction] = sort.split("+");

    const clear = () => {
        setIsClearing(true);
        searchTrigger({ page: 1, limit, sort, ...filter }).finally(() => setIsClearing(false));
    };

    const isRowLoaded = (index) => index < results.length;

    const loadMore = () => {
        searchTrigger({ page: page + 1, limit, sort, ...filter });
    };

    const onClickSort = (property) => {
        // we want the sort to initially be descending ??
        let newDirection = "";

        // if the current property is already selected, reverse the sort direction
        if (property === sortProperty) {
            newDirection = direction === "DESC" ? null : "+DESC";
        }

        setSort(property + (newDirection ?? ""));
    };

    const reload = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }

        clear();
    };

    useEffect(() => {
        if (isUninitialized) return;

        clear();
    }, [filter]);

    useEffect(() => {
        if (isUninitialized) return;

        clear();
    }, [sort]);

    const sortableHeaderRenderer = ({ label, property, variant, key }) => {
        return <StandardTableSortableHeader
            key={key}
            onClick={() => onClickSort(property)}
            active={property === sortProperty}
            direction={direction}
        >
            {label}
        </StandardTableSortableHeader>;
    };

    const columns = [
        {
            label: "Status",
            property: 'status',
            headerRenderer: sortableHeaderRenderer
        },
        {
            label: "Status Date",
            property: 'applicationStatusDate',
            headerRenderer: sortableHeaderRenderer
        },
        {
            label: "Comment",
            property: "comment",
            headerRenderer: sortableHeaderRenderer
        },
        {
            label: "Designation",
            property: "designationName",
            headerRenderer: sortableHeaderRenderer
        },
        {
            label: "Provider",
            property: "providerName",
            headerRenderer: sortableHeaderRenderer
        },
        {
            label: "Amount",
            property: "amount",
            headerRenderer: sortableHeaderRenderer
        },
        {
            label: "Description",
            property: "description",
            headerRenderer: sortableHeaderRenderer
        },
        {
            label: "Reference",
            property: "createdDate",
            headerRenderer: sortableHeaderRenderer
        }
    ];

    return {
        results,
        sort,
        totalCount,
        columns,
        isClearing,
        isError,
        error,
        clear,
        isRowLoaded,
        loadMore,
        sortableHeaderRenderer,
        reload,
    };
};

export default useApplications;