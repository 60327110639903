import _ from "lodash";
import React from "react";
import { usePatchReportMutation } from "../../../services/clientreports";

const EditReportContext = React.createContext();

export const useEditReport = () => React.useContext(EditReportContext);

export const EditReportProvider = ({ children, showModalOnReportSet = true }) => {
    const [report, _setReport] = React.useState(null);
    const [showModal, _setShowModal] = React.useState(false);
    const [patch, { isLoading, ...patchResults }] = usePatchReportMutation();

    const clear = () => {
        _setReport(_ => null);
        _setShowModal(_ => false);
    };

    const saveChanges = (operations) => {
        const { id, masterAccountId, projectId } = report;
        return new Promise((resolve, reject) => {
            return patch({ masterAccountId, reportId: id, projectId, operations }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    const setReport = (report) => {
        _setReport(_ => report);
        if (showModalOnReportSet === true) {
            _setShowModal(_ => true);
        }
    };

    return (
        <EditReportContext.Provider value={{ 
            clear,
            isLoading,
            isModalActive: showModal,
            patch: { isLoading, ...patchResults },
            report,
            saveChanges,
            setReport
        }}>
            {children}
        </EditReportContext.Provider>
    );
};