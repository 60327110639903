import _ from "lodash";
import React, { useEffect } from "react";
import ReportCategoriesList from "./components/ReportCategoriesList";
import ReportProductAreasList from "./components/ReportProductAreasList";
import ReportTypeMappingConfirmModal from "./components/ReportTypeMappingConfirmModal";
import ReportTypesSelect from "../../components/forms/selects/ReportTypesSelect";
import ClientProjectSearch from "../clientprojectsearch";
import Button from "../../components/buttons";
import toast from "react-hot-toast";
import { useAuth } from "react-oidc-context";
import { DateInput, FormInput, NumberInput } from "../../components/forms";
import { ReportAuthorSelect, ReportTemplateSelect } from "../../components/forms/selects";
import { BasicToast } from "../../components/toasts";
import { useClientContext } from "../../hooks/ClientContext";
import { Modal } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { useForm, Controller } from "react-hook-form";
import { useTheme } from "styled-components";
import useCreateReport from "./hooks/useCreateReport";
import useOpenDocument from "../../hooks/useOpenDocument";

export const CreateReportModalButton = ({ className, variant = "success", ...rest }) => {
    return <div className={className}>
        <Button variant={variant} {...rest}>Create Report</Button>
    </div>
};

const CreateReportModal = ({
    allowAssignAuthorRoles = ["sys_admin_access", "report_assign_author", "report_assign_self"],
    allowSetDeadlineDateRoles = ["sys_admin_access", "report_admin", "report_adjust_deadline"],
    allowSetEstimatedMinutesRoles = ["sys_admin_access", "report_admin"],
    defaultProjectId = null,
    displayToast = true,
    handleClose,
    onCreateProjectClicked,
    onReportCreated,
    show,
    ...rest
}) => {
    const theme = useTheme(); // NOTE: we need the theme property to pass it to our confirm pop-up
    const { user: { profile } } = useAuth();
    const { account: { divisionId, masterAccountId } } = useClientContext();
    const { openDocument, isFetching: isOpening } = useOpenDocument();
    const { createResults: { isLoading }, defaultValues, handleCreate } = useCreateReport(masterAccountId, defaultProjectId);
    const { control, formState: { errors }, getValues, handleSubmit, reset, setValue } = useForm({ defaultValues });
    const isAllowedToAssignAuthor = _.some(profile.roles, item => allowAssignAuthorRoles.includes(item));
    const isAllowedToSetDeadlineDate = _.some(profile.roles, item => allowSetDeadlineDateRoles.includes(item));
    const isAllowedToSetEstimatedMinutes = _.some(profile.roles, item => allowSetEstimatedMinutesRoles.includes(item));

    const assignReportTypeMappings = (categories, productAreas, reportType) => {
        // updates the form values for the useFieldArray setup in the child components
        if (reportType) {
            setValue('estimatedMinutesToComplete', reportType?.data?.defaultEstimatedMinutes ?? 30);
        }
        setValue('categories', categories.map(el => { return { value: el, description: el } }));
        setValue('productAreas', productAreas.map(el => { return { value: el, description: el } }));
    };

    const onClose = () => {
        reset(defaultValues);
        handleClose();
    };

    const onOpenReport = (report) => {
        const { documentId, reportName } = report;

        if (!documentId || isOpening)
            return;

        openDocument(documentId, reportName);
    };

    const onReportTypeSelected = (selection) => {
        // update the actual field-id that we would be doing originally before overriding the onChange
        setValue('typeId', selection.value);

        // attempt to pull-out the data we need for assinging the type mappings
        const { data } = selection || {};
        const { mappedCategories: categories, mappedProductAreas: productAreas } = data || { categories: [], productAreas: [] };
        if (categories.length === 0 && productAreas.length === 0) {
            setValue('estimatedMinutesToComplete', selection?.data?.defaultEstimatedMinutes ?? 30);
            return;
        }

        // prompt the user to confirm they want their mappings changed as they might have manually set them up before-hand
        // NOTE: do not overwrite what a user has already input. Perhaps we can check if they have already "dirtied" the fields before
        // This should be possible with the react-form-hooks that we are using for this section
        confirmAlert({
            closeOnEscape: false,
            closeOnClickOutside: false,
            customUI: (props) => {
                return <ReportTypeMappingConfirmModal
                    handleConfirm={() => assignReportTypeMappings(categories, productAreas, selection)}
                    selection={selection}
                    theme={theme}
                    {...props}
                />
            }
        })
    };

    const onSubmit = (data) => {
        handleCreate(data).then(
            r => {
                if (onReportCreated && typeof (onReportCreated) === 'function') {
                    onReportCreated(r);
                }
                if (displayToast === true) {
                    toast.custom((t) => <BasicToast
                        control={t}
                        date={r?.createdDate}
                        icon="fa-solid fa-file-word"
                        title="Report Generated"
                        message={`Congratulations. 🥳 Your report: '${r.reportName}' has been generated successfully.`}
                        action={() => {
                            onOpenReport(r);
                            toast.dismiss(t.id);
                        }}
                        actionLabel="Open Report"
                    />, { duration: 8000 });
                }
            },
            e => toast.error(e?.data?.message)
        )
            .finally(onClose);
    };

    useEffect(() => {
        var currentProjectId = getValues("projectId");
        if (!_.isEqual(defaultProjectId, currentProjectId)) {
            setValue('projectId', defaultProjectId);
        }
    }, [defaultProjectId]);

    return <Modal size="lg" centered backdrop="static" show={show}>
        <Modal.Header>
            <Modal.Title>Create a New Report</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Body>
                <div className="row mb-3">
                    <div className="col">
                        <Controller
                            name="projectId"
                            control={control}
                            rules={({ required: "A Project must be assigned to a Report." })}
                            render={({ field: { ref, value, onChange, ...rest } }) => (
                                <ClientProjectSearch
                                    buttonSize="sm"
                                    label="Project"
                                    labelPosition="top"
                                    formError={errors?.projectId?.message}
                                    value={value}
                                    onProjectCreated={(project) => setValue('projectId', project.projectId)}
                                    onProjectSelected={(_, project) => setValue('projectId', project.projectId)}
                                    {...rest}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <div className="col">
                        <Controller
                            name="reportName"
                            control={control}
                            rules={{ required: "Report Name is required." }}
                            render={({ field: { ref, ...rest } }) => <FormInput label="Report Name" disableAnimations={true} errorMessage={errors?.reportName?.message} errorAllowRetry={false} {...rest} />}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <Controller
                            name="typeId"
                            control={control}
                            rules={{ required: "A Report Type must be selected." }}
                            render={({ field: { ref, value, onChange, ...rest } }) => (
                                <ReportTypesSelect
                                    label="Report Type"
                                    defaultValue={value}
                                    errorMessage={errors?.typeId?.message}
                                    errorAllowRetry={false}
                                    onChange={onReportTypeSelected}
                                    {...rest}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <Controller
                            name="templateId"
                            control={control}
                            render={({ field: { ref, value, onChange, ...rest } }) => (
                                <ReportTemplateSelect
                                    label="Borrow / Template"
                                    defaultValue={value}
                                    disableAnimations={true}
                                    errorAllowRetry={false}
                                    placeholder="No Template Selected."
                                    onChange={(values) => setValue('templateId', values.value)}
                                    {...rest}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-6">
                        <Controller
                            name="authorId"
                            control={control}
                            render={({ field: { ref, value, onChange, ...rest } }) => (
                                <ReportAuthorSelect
                                    label="Author"
                                    defaultValue={value}
                                    divisionId={divisionId}
                                    disableAnimations={true}
                                    isClearable={true}
                                    isDisabled={!isAllowedToAssignAuthor}
                                    placeholder="No Author assigned."
                                    onChange={(values) => setValue('authorId', values.value)}
                                    {...rest}
                                />
                            )}
                        />
                    </div>
                    <div className="col">
                        <Controller
                            name="estimatedMinutesToComplete"
                            control={control}
                            render={({ field: { ref, ...rest } }) => (
                                <NumberInput 
                                    label="Estimated Minutes" 
                                    disableAnimations={true} 
                                    decimalScale={0} 
                                    disabled={!isAllowedToSetEstimatedMinutes}
                                    errorMessage={errors?.estimatedMinutesToComplete?.message}
                                    errorAllowRetry={false} 
                                    {...rest} 
                                />
                            )}
                        />
                    </div>
                    <div className="col">
                        <Controller
                            name="deadlineDate"
                            control={control}
                            render={({ field: { ref, onBlur, onChange, ...rest } }) => (
                                <DateInput
                                    label="Deadline"
                                    placeholder="Choose Report deadline."
                                    disableAnimations={true}
                                    isClearable
                                    disabled={!isAllowedToSetDeadlineDate}
                                    onBlur={(value, e) => {
                                        if (value !== "Invalid date") {
                                            setValue('deadlineDate', value);
                                        }
                                    }}
                                    {...rest}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <ReportCategoriesList control={control} name="categories" />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <ReportProductAreasList control={control} name="productAreas" />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" type="submit" disabled={isLoading === true}>Create Report</Button>
                <Button variant="light" onClick={onClose} disabled={isLoading === true}>Close</Button>
            </Modal.Footer>
        </form>
    </Modal>
}

export default CreateReportModal;