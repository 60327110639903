import React from "react";
import DateDisplay from "../../../components/DateDisplay";
import ReportEfficacyDisplay, { ReportEfficacyNumberDisplay } from "../../../components/ReportEfficacyDisplay";
import useReportStatusHistory from "../hooks/useReportStatusHistory";
import { ProfileDisplay } from "../../../components";
import { Stars, ThemedSpan } from "../../../components/utilities";
import { useReportHistory } from "../hooks/ReportHistoryContext";
import { LoadingMessage } from "../../../components/loaders";

const ReportStatusHistory = () => {
    const { report: { masterAccountId, id } } = useReportHistory();
    const { data, isLoading } = useReportStatusHistory(masterAccountId, id);

    if (isLoading === true || data === null) {
        return <LoadingMessage text="Please wait whilst WealthPro gets the Report Summary Data" />
    }

    const { 
        authorId,
        authors,
        averageFeedbackRating,
        complianceUserId,
        currentStatus, 
        currentStatusDate, 
        efficacy, 
        history,
        rejectCount,
        reportName,
        typeDescription
    } = data;
    return (
        <div className="p-3">
            <div className="p-1 mb-2">
                <div className="card">
                    <div className="card-body">
                        <p>
                            <ThemedSpan variant="primary" className="fs-4">{reportName}</ThemedSpan>
                            <br/>
                            <span className="fs-6 text-muted fst-italic">{typeDescription}</span>
                        </p>
                        <div className="row mb-2">
                            <div className="col-4">
                                <span className="fw-bold me-1">Status:</span> 
                                <span className="me-1">{currentStatus}</span> 
                                <ThemedSpan variant="muted" className="fst-italic" style={{ fontSize: '.82rem' }}>
                                    (<DateDisplay>{currentStatusDate}</DateDisplay>)
                                </ThemedSpan>
                            </div>
                            <div className="col-4 d-flex flex-row">
                                <span className="fw-bold me-1">Author:</span>
                                {authorId 
                                    ? (<ProfileDisplay userId={authorId} />) 
                                    : (<ThemedSpan variant="muted" className="fst-italic">Not Assigned</ThemedSpan>)
                                }
                            </div>
                            <div className="col-4 d-flex flex-row">
                                <span className="fw-bold me-1">Compliance:</span> 
                                {complianceUserId 
                                    ? (<ProfileDisplay userId={complianceUserId} />) 
                                    : (<ThemedSpan variant="muted" className="fst-italic">Not Assigned</ThemedSpan>)
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3">
                                <span className="fw-bold me-1">Reject Count:</span>
                                <span>{rejectCount}</span>
                            </div>
                            <div className="col-3">
                                <span className="fw-bold me-1">Quality:</span>
                                <ReportEfficacyDisplay efficacy={efficacy} />
                            </div>
                            <div className="col-6 d-flex flex-row">
                                <span className="fw-bold me-1">Feedback Avg. Score:</span>
                                <Stars count={(averageFeedbackRating)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-row">
                <div className="flex-fill p-1">
                    <div className="card">
                        <div className="card-body">
                            <div className="fs-4 mb-2">
                                <ThemedSpan variant="primary">Status History</ThemedSpan>
                            </div>
                            <div>
                                <div className="d-flex flex-row justify-content-between fw-bold">
                                    <div>{currentStatus}</div>
                                    <div><DateDisplay>{currentStatusDate}</DateDisplay></div>
                                </div>
                                {history && history.length > 0 && history.map((rec, i) => (
                                    <div className="d-flex flex-row justify-content-between" key={i}>
                                        <div>{rec.status}</div>
                                        <div><DateDisplay>{rec.statusSetDate}</DateDisplay></div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex-fill p-1">
                    <div className="card">
                        <div className="card-body">
                            <div className="fs-4 mb-2">
                                <ThemedSpan variant="primary">Author Breakdown</ThemedSpan>
                            </div>
                            <div>
                                {(!authors || authors.length === 0) && (
                                    <ThemedSpan variant="muted">There have been no authors assigned to this report.</ThemedSpan>
                                )}
                                {authors.map((rec, i) => (
                                    <div className="d-flex flex-row justify-content-between align-items-center" key={i}>
                                        <div>
                                            <ProfileDisplay userId={rec.authorId} />
                                        </div>
                                        <div>Times Rejected: {rec.rejectCount}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

/*
{(!history || history.length === 0) && (
    <ThemedSpan variant="muted">There is no report status history available.</ThemedSpan>
)}
*/

export default ReportStatusHistory;