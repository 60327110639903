import _ from "lodash";
import React from "react";
import { Modal } from "react-bootstrap";
import { useAuth } from "react-oidc-context";
import { useForm, Controller } from "react-hook-form";
import { Button } from "../../../components";
import { DateInput, FormInput, NumberInput } from "../../../components/forms";
import { GenericUserSelect, ReportAuthorSelect, ReportStatusSelect, ReportTypesSelect } from "../../../components/forms/selects";

const EditClientAuthorModal = ({ 
    allowAssignAuthorRoles = ["sys_admin_access", "report_assign_author", "report_assign_self"],
    allowSetDeadlineDateRoles = ["sys_admin_access", "report_adjust_deadline"],
    allowAdjustMinutesRoles = ["sys_admin_access", "report_adjust_minutes"],
    allowAdjustTypeRoles = ["sys_admin_access", "report_adjust_type"],
    report = null, 
    show = false, 
    isLoading = false, 
    handleSave = () => {}, 
    handleClose = () => {}
}) => {
    const { user: { profile } } = useAuth();
    const { control, errors, formState, getValues, handleSubmit, reset, setValue } = useForm({ defaultValues: report });
    const isAllowedToAssignAuthor = _.some(profile.roles, item => allowAssignAuthorRoles.includes(item));
    const isAllowedToSetDeadlineDate = _.some(profile.roles, item => allowSetDeadlineDateRoles.includes(item));
    const isAllowedToSetActualMinutes = _.some(profile.roles, item => allowAdjustMinutesRoles.includes(item));
    const isAllowedToSetReportType = _.some(profile.roles, item => allowAdjustTypeRoles.includes(item));

    const onSubmit = (data) => {
        const { dirtyFields } = formState;
        if (!dirtyFields || Object.keys(dirtyFields).length === 0) {
            handleClose();
        }

        const operations = Object.keys(dirtyFields).map(el => {
            return { op: 'replace', path: `/${el}`, value: getValues(el) };
        });

        if (!operations || operations.length === 0) {
            handleClose();
        }

        handleSave(operations);
    };

    React.useEffect(() => {
        if (!report) {
            return;
        }
        reset(report);
    }, [ report ])

    return (
        <Modal size="lg" centered backdrop="static" show={show}>
            <Modal.Header>
                <Modal.Title>Set Report Details</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                <div className="row mb-3">
                    <div className="col">
                        <Controller
                            name="reportName"
                            control={control}
                            rules={{ required: "Report Name is required." }}
                            render={({ field: { ref, onChange, ...rest } }) => (
                                <FormInput 
                                    label="Report Name" 
                                    disableAnimations={true}
                                    disabled={isLoading} 
                                    onChange={(e) => setValue('reportName', e.target.value, { shouldDirty: true })}
                                    {...rest} 
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <Controller
                            name="typeId"
                            control={control}
                            rules={{ required: "A Report Type must be selected." }}
                            render={({ field: { ref, value, onChange, ...rest } }) => (
                                <ReportTypesSelect
                                    label="Report Type"
                                    defaultValue={value}
                                    disableAnimations={true}
                                    isDisabled={!isAllowedToSetReportType || isLoading}
                                    onChange={(values) => setValue('typeId', values.value, { shouldDirty: true })}
                                    {...rest}
                                />
                            )}
                        />
                    </div>
                </div>
                    <div className="row mb-3">
                        <div className="col-6">
                            <Controller
                                name="authorId"
                                control={control}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <ReportAuthorSelect
                                        label="Author"
                                        defaultValue={value}
                                        disableAnimations={true}
                                        isDisabled={!isAllowedToAssignAuthor || isLoading}
                                        placeholder="No Author assigned."
                                        onChange={(values) => setValue('authorId', values.value, { shouldDirty: true })}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col">
                            <Controller
                                name="actualMinutesToComplete"
                                control={control}
                                render={({ field: { ref, onChange, ...rest } }) => (
                                    <NumberInput 
                                        label="Minutes to Complete" 
                                        disableAnimations={true} 
                                        decimalScale={0} 
                                        errorMessage={errors?.actualMinutesToComplete?.message}
                                        errorAllowRetry={false}
                                        disabled={!isAllowedToSetActualMinutes || isLoading}
                                        onChange={(e, value) => setValue('actualMinutesToComplete', value.floatValue, { shouldDirty: true })}
                                        {...rest} />
                                )}
                            />
                        </div>
                        <div className="col">
                            <Controller
                                name="deadlineDate"
                                control={control}
                                render={({ field: { ref, onBlur, onChange, ...rest } }) => (
                                    <DateInput
                                        label="Deadline"
                                        placeholder="Choose Report deadline."
                                        disableAnimations={true}
                                        isClearable
                                        disabled={!isAllowedToSetDeadlineDate || isLoading}
                                        onBlur={(value, e) => {
                                            if (value !== "Invalid date") {
                                                setValue('deadlineDate', value, { shouldDirty: true });
                                            }
                                        }}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col">
                            <Controller
                                name="statusId"
                                control={control}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <ReportStatusSelect
                                        label="Status"
                                        defaultValue={value}
                                        disableAnimations={true}
                                        isDisabled={isLoading || !report?.authorId}
                                        masterAccountId={report?.masterAccountId}
                                        reportId={report?.id}
                                        onChange={(values) => setValue('statusId', values.value, { shouldDirty: true })}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                        <div className="col">
                            <Controller
                                name="complianceUserId"
                                control={control}
                                render={({ field: { ref, value, onChange, ...rest } }) => (
                                    <GenericUserSelect
                                        label="Compliance User"
                                        defaultValue={value}
                                        disableAnimations={true}
                                        isDisabled={!isAllowedToAssignAuthor || isLoading}
                                        placeholder="No Compliance user assigned."
                                        onChange={(values) => setValue('complianceUserId', values.value, { shouldDirty: true })}
                                        {...rest}
                                    />
                                )}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" type="submit" disabled={isLoading === true}>Save Changes</Button>
                    <Button variant="light" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default EditClientAuthorModal;