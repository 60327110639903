import _ from "lodash";
import React, { useEffect, useState } from "react";
import { ButtonGroup, Modal } from "react-bootstrap";
import Button from "../../components/buttons";
import { useFilterContext } from "../../hooks/FilterContext";
import { DateInput, FormSelect } from "../../components/forms";
import { GenericAdviserSelect } from "../../components/forms/selects";
import useOrganisations from "./hooks/useOrganisations";

export const NewMoneyFiltersModal = ({
    handleClose,
    onFiltersChanged,
    show,
    ...rest
}) => {
    const { defaultFilters, filter, setFilter } = useFilterContext();
    const [tempFilters, setTempFilters] = useState(filter);

    const onApplyFilters = () => {
        setFilter((original) => ({
            ...original,
            ...tempFilters,
        }));
        onClose();
    };

    const onClearFilters = () => {
        setTempFilters(defaultFilters);
        // onClose();
    };

    const onFilterValueChanged = (property, value) => {
        setTempFilters((original) => {
            return {
                ...original,
                [property]: value,
            };
        });
    };

    const onClose = () => {
        if (handleClose && typeof handleClose === "function") {
            setTempFilters(filter);
            handleClose();
        }
    };

    useEffect(() => {
        setTempFilters(oldTemp =>
            !_.isEqual(oldTemp, filter)
                ? filter
                : oldTemp
        );
    }, [filter]);

    return <Modal size="lg" centered backdrop="static" show={show} onHide={handleClose}>
        <Modal.Header closeButton>New Money Filters</Modal.Header>
        <Modal.Body {...rest}>
            <NewMoneyFilters
                filter={tempFilters}
                setFilter={onFilterValueChanged}
            />
        </Modal.Body>
        <Modal.Footer>
            <ButtonGroup>
                <Button variant="light" onClick={onClose}>
                    Cancel
                </Button>
                <Button variant="warning" onClick={onClearFilters}>
                    Clear Filters
                </Button>
                <Button variant="success" onClick={onApplyFilters}>
                    Apply Filters
                </Button>
            </ButtonGroup>
        </Modal.Footer>
    </Modal>;
};

const NewMoneyFilters = ({ filter, setFilter }) => {

    const {
        organisations,
        isLoadingOrganisations,
    } = useOrganisations();

    const {
        orgId,
        // divId,
        adviserIds,
        statuses,
        startDate,
        endDate
    } = filter;

    return <>
        <div className="row mb-3">
            <div className="col">
                <FormSelect
                    label="Organisation"
                    defaultValue={orgId}
                    onChange={selection => setFilter("orgId", selection.value)}
                    options={organisations}
                    isLoadingOptions={isLoadingOrganisations}
                    disableAnimations
                />
            </div>
            {/* <div className="col">
                <FormSelect
                    label="Division"
                    disableAnimations
                    defaultValue={divId}
                    onChange={selection => setFilter("divId", selection.value)}
                    options={[]}
                />
            </div> */}
            <div className="col">
                <GenericAdviserSelect
                    label="Adviser"
                    defaultValue={adviserIds}
                    onChange={(selections) => setFilter("adviserIds", selections.map(selection => selection.value))}
                    disableAnimations
                    isMulti
                />
            </div>
        </div>
        <div className="row">
            <div className="col-6">
                <FormSelect
                    label="Status"
                    defaultValue={statuses}
                    onChange={selected => setFilter("statuses", selected.map(s => s.value))}
                    options={[
                        { label: "Pending", value: 0 },
                        { label: "Approved", value: 1 },
                        { label: "Not Taken Up", value: 2 }
                    ]}
                    disableAnimations
                    isMulti
                />
            </div>
            <div className="col">
                <DateInput
                    label="New Money Date From"
                    disableAnimations
                    value={startDate ?? ""}
                    onBlur={(date) => setFilter("startDate", date)}
                    isClearable
                />
            </div>
            <div className="col">
                <DateInput
                    label="To"
                    disableAnimations
                    value={endDate ?? ""}
                    onBlur={(date) => setFilter("endDate", date)}
                    isClearable
                />
            </div>
        </div>
    </>;
};

export default NewMoneyFilters;
