import { Modal } from "react-bootstrap";
import { Button } from "../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import CreateMessageForm from "./components/CreateMessageForm";
import { useForm } from "react-hook-form";
import useCreateMessageForm from "./hooks/useCreateMessageForm";
import { BasicToast } from "../../components/toasts";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import RetryDocumentUploadModal from "./components/RetryDocumentUploadModal";


export const CreatePersonalMessageButton = ({ className, onClick, ...rest }) => {

    return (
        <Button className={classNames("d-flex align-items-center", className)} onClick={onClick} variant="success" {...rest}>
            <FontAwesomeIcon className="me-1" icon={"fa-plus"} />
            <span>Create Message</span>
        </Button>
    );
};

const CreatePersonalMessageModal = ({ show, onCreated, onHide }) => {

    const [showRetryModal, setShowRetryModal] = useState(false);
    const { createMessage, retryDocumentUploads, createResult, formDefaultValues: defaultValues } = useCreateMessageForm();
    const { data, isLoading } = createResult || {};
    const { attachments: attachmentsResponse } = data || {};

    const form = useForm({ defaultValues });
    const { getValues, reset, handleSubmit, watch } = form;

    const attachments = watch("attachments");
    const attachmentsInfo = watch("attachmentsInfo");

    const onClose = (e) => {
        if (typeof e?.preventDefault === "function") {
            e.preventDefault();
        }

        reset(defaultValues);
        setShowRetryModal(false);
        onHide();
    };

    const onSubmit = (data, e, sendOnCreate = true) => {
        createMessage(data, sendOnCreate).then(
            r => {
                if (onCreated && typeof (onCreated) === 'function') {
                    onCreated(r);
                }

                toast.custom((t) => (
                    <BasicToast
                        control={t}
                        date={r?.createdDate}
                        icon="fa-solid fa-list-check"
                        title={sendOnCreate ? "Message Sent" : "Draft Created"}
                        message={sendOnCreate ? "Message has been sent" : "Draft has been created"}
                    />
                ), { duration: 8000 });

            },
            e => toast.error(e?.data?.message ?? sendOnCreate ? "Unable to send message" : "Unable to create draft")
        );
    }

    useEffect(() => {
        if (!createResult.isSuccess) return;

        if (Array.isArray(attachmentsResponse) && attachmentsResponse.every(x => x.documentId)) {
            onClose();
            return
        }

        setShowRetryModal(true);

    }, [createResult]);

    return (
        <Modal centered backdrop="static" size="lg" show={show} onHide={onClose}>
            <Modal.Header closeButton>Create Message</Modal.Header>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <CreateMessageForm
                        attachmentsResponse={attachmentsResponse}
                        onSubmit={onSubmit}
                        {...form}
                    />
                    <RetryDocumentUploadModal
                        message={data}
                        show={showRetryModal}
                        attachmentsResponse={attachmentsResponse}
                        files={attachments}
                        fileInfos={attachmentsInfo}
                        onClose={onClose}
                        uploadDocuments={retryDocumentUploads}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={attachments?.length > 0} onClick={(e) => onSubmit(getValues(), e, false)} variant="muted">Save as Draft</Button>
                    <Button variant="success" type="submit" disabled={isLoading}>Send Message</Button>
                    <Button variant="light" onClick={onClose}>Cancel</Button>
                </Modal.Footer>

            </form>
        </Modal>
    );
};


export default CreatePersonalMessageModal;