import { wealthProApi } from '../../redux/api';

const reportStatusEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        fetchAvailableReportStatusesList: builder.query({
            query: ({ masterAccountId, reportId }) => `/api/reports/master-accounts/${masterAccountId}/reports/${reportId}/status/list`
        }),
        fetchReportStatuses: builder.query({
            query: () => `/api/reports/report-statuses`
        }),
        fetchReportStatusesList: builder.query({
            query: ({ masterAccountId, reportId }) => masterAccountId && reportId
                ? `/api/reports/master-accounts/${masterAccountId}/reports/${reportId}/status/list`
                : `/api/reports/report-statuses/list`
        })
    })
});

export const {
    useFetchAvailableReportStatusesListQuery,
    useFetchReportStatusesQuery,
    useFetchReportStatusesListQuery
} = reportStatusEndpoints;