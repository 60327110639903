import React from "react";
import { ReminderDisplayList } from "../../reminders";
import { useLazyFetchReminderTasksQuery } from "../../../services/reminders";
import { useClientContext } from "../../../hooks/ClientContext";
import { AccordionSection } from "../../../components/utilities";

const ProjectReminders = ({ label = "Events, Appointments, Phone Calls and CPD", onProjectChanged, selectedProject }) => {
    const { id } = useClientContext();
    const { projectId } = selectedProject || { projectId: null };
    const [loadReminders, loadResults] = useLazyFetchReminderTasksQuery();

    React.useEffect(() => {
        loadReminders({ masterAccountId: id, projectId, showCancelled: false })
    }, [ id, projectId ]);

    return (
        <React.Fragment>
            <AccordionSection label={label} defaultActiveKey="1">
                <ReminderDisplayList 
                    projectId={projectId} 
                    refetch={loadReminders}
                    displayProject={false} 
                    {...loadResults} 
                />
            </AccordionSection>
        </React.Fragment>
    );
};

export default ProjectReminders;