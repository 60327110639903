import _ from "lodash";
import React, { useContext, useState } from "react";
import moment from "moment";

const FilterContext = React.createContext();

export const useFilterContext = () => useContext(FilterContext);


export const FilterProvider = ({ children, filterStore = null, defaultFilters = {} }) => {
    const [filter, setFilter] = useState(defaultFilters);
    // Allows the calling component to wait for session storage to be read before fetching with the filters
    const [isReady, setIsReady] = useState(false);
    const [showFilterModal, setShowFilterModal] = useState(false);

    /**
     * Clear the filters back to either the default or a specific set of filters
     */
    const clearFilter = (e, filters = null) => {
        if (e && typeof e.preventDefault === "function") {
            e.preventDefault();
        }

        if (filterStore && sessionStorage.getItem(`filters.${filterStore}`)) {
            sessionStorage.removeItem(`filters.${filterStore}`);
        }

        setFilter((_) => filters ?? defaultFilters);
    };

    /**
     * Updates the current filter with a new set of filters that need to be applied.
     * @param {*} param0 
     */
    const setNewFilter = ({ newFilter }) => {
        if (filterStore) {

        }

        setFilter(_ => newFilter);
    }

    React.useEffect(() => {
        if (!filterStore || _.isEqual(filter, defaultFilters)) {
            return;
        }
        sessionStorage.setItem(`filters.${filterStore}`, JSON.stringify(filter));
    }, [filter]);

    /**
     * Run on component/content/provider mount. Will decide what to set the filters
     */
    React.useEffect(() => {
        // when the filter store has not been set, then lets apply the default filters
        if (!filterStore) {
            setFilter(_ => defaultFilters);
            setIsReady(true);
            return;
        }

        // try and pull the value out of session storage if we have applied the "store"
        const sessionFiltersString = sessionStorage.getItem(`filters.${filterStore}`);
        const sessionFilters = sessionFiltersString != null ? JSON.parse(sessionFiltersString) : defaultFilters;
        setFilter(_ => sessionFilters);
        setIsReady(true);
    }, [defaultFilters]);

    return (
        <FilterContext.Provider
            value={{
                clearFilter,
                defaultFilters,
                filter,
                isFiltersApplied: !_.isEqual(filter, defaultFilters),
                isReady,
                setFilter,
                setNewFilter,
                showFilterModal,
                setShowFilterModal
            }}
        >
            {children}
        </FilterContext.Provider>
    );
};

export const ReportManagementDefaultFilters = {
    reportStatuses: [],
    efficacy: [],
    masterAccountIds: [],
    masterAccounts: [],
    reportTypes: [],
    reportCategories: [],
    reportProductAreas: [],
    serviceTermIds: [],
    adviserIds: [],
    authorUserIds: [],
    complianceUserIds: [],
    mediaTypes: [],
    authorAssignedStartDate: null,
    authorAssignedEndDate: null,
    passedComplianceStartDate: null,
    passedComplianceEndDate: null,
    sentToClientStartDate: null,
    sentToClientEndDate: null,
    reportFeedbackFilters: [],
    searchTerm: ""
};

//Filter reviews from Jan 1st of the current year to the last day of the current month

export const ReviewManagementDefaultFilters = {
    reviewStatuses: [0, 3, 4],
    reviewDateStart: moment().startOf('year').format('YYYY/MM/DD'),
    reviewDateEnd: moment().endOf('month').format('YYYY/MM/DD'),
    serviceTermIds: [],
    adviserIds: [],
    completedTasks: [],
    overdueTasks: [],
    searchTerm: ""
};

export const AdministrationDashboardDefaultFilters = {
    reference: null,
    accountName: null,
    statuses: [],
    startDate: null,
    endDate: null,
    organisationId: null,
    divisionId: null,
    adviserIds: []
};


export const AdministrationRemindersDefaultFilters = {
    message: null,
    reminderDateStart: null,
    reminderDateEnd: null,
    completedByIds: [],
    completedDateStart: null,
    completedDateEnd: null
};

export const AdvicePendingDefaultFilters = {
    reference: null,
    accountName: null,
    statuses: [],
    startDate: null,
    endDate: null,
    organisationId: null,
    divisionId: null,
    adviserIds: [],
    filterModel: []
};

export const CorporateActionPairFilters = {
    description: null,
    sedolIn: null,
    sedolOut: null,
    startDate: null,
    endDate: null
};

export const FeeManagementDefaultFilters = {
    organisationId: null,
    divisionId: null,
    adviserIds: [],
    advisers: [],
    introducerIds: [],
    introducers: [],
    providerIds: [],
    providers: [],
    feeTypes: [],
    masterAccountIds: [],
    masterAccounts: [],
    startDate: moment().startOf('month').toISOString(),
    endDate: null
};

export const IntroducerDashboardDefaultFilters = {
    introducer: "",
    typeIds: [],
    statusesIds: [],
    divisionIds: [],
    teamIds: [],
    adviserIds: []
};

export const InvestmentCommitteeFundDefaultFilters = {
    // Shared between Analysis/Management
    assetName: null,
    monitoring: [0],
    standardPortfolio: [],
    // Analysis only
    volatilityProfile: [],
    assetClass: [],
    management: [],
    region: [],
    style: [],
    // Management only
    controlStatus: [],
    opinion: [],
    guidance: [],
    analystId: [],
    publishedStartDate: null,
    publishedEndDate: null,
    opinionModifiedStartDate: null,
    opinionModifiedEndDate: null,
    publishedById: [],
};

export const InvestmentTransactionDefaultFilters = {
    designationIds: [],
    investmentOperationIds: [],
    providerIds: [],
    wrapperIds: [],
    productIds: [],
    assetIds: [],
    assetIdObjects: [],
    investmentPartnerIds: [],
    statusIds: [],
    custodianReference: null,
    providerReference: null,
    quantity: null,
    value: null,
    dateFrom: null,
    dateTo: null,
    includeInValuation: true,
    isActive: true
};

export const ProviderDefaultFilters = {
    custodianCode: null,
    fundManagerCode: null,
    providerName: null
};

export const NewMoneyDefaultFilters = {
    orgId: null,
    // divId: null,
    adviserIds: [],
    statuses: [],
    startDate: null,
    endDate: null,
}

export const ProjectManagementDefaultFilters = {
    divId: null,
    adviserIds: [],
    masterAccountIds: [],
    targetStartDate: moment().startOf('month').format("YYYY-MM-DD"),
    targetEndDate: moment().endOf('month').format("YYYY-MM-DD"),
    createdStartDate: null,
    createdEndDate: null,
    campaignId: null,
    campaignStatusIds: [],
    projectName: null,
    projectStatuses: [0], // Default to In Progress only
    adviceTypes: []
};

export const ApplicationDocumentsDefaultFilters = {
    fileName: null,
    userId: null,
    documentType: null,
    uploadedDateFrom: null,
    uploadedDateTo: null
};

export const UserDelegateSearchDefaultFilters = {
    userIds: [],
    delegateUserIds: [],
    createdByIds: [],
    readCalendar: false,
    writeCalendar: false,
    readMail: false,
    writeMail: false,
    sendMail: false,
    readMessages: false,
    writeMessages: false,
    readReminders: false,
    writeReminders: false,
    hasClientAccess: false
};

export const UsersGridDefaultFilters = { };