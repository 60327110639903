import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { lighten } from "polished";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";
import { ErrorMessage, ErrorMessageWrapper } from "../../components/messages";
import InfinityList from "../../components/tables/InfinityList";
import { ThemedSpan, Tooltip } from "../../components/utilities";
import { usePersonalOutlookContext } from "../../hooks/PersonalOutlookContext";
import useMessageSummaries from "./hooks/useMessageSummaries";
import { IconButton } from "../../components";
import toast from "react-hot-toast";

const EmailLoadingPreview = ({ style }) => (
    <div className="border p-2" style={style}>
        <Skeleton
            height={24}
            count={3}
        />
    </div>
);

const EmailPreview = styled.div`
    &.active{
        background-color: ${props => lighten(0.4, props.theme.secondary)};
    }
    &:hover {
        background-color: ${props => lighten(0.475, props.theme.secondary)};
    }
`;

const EmailPreviewToolBar = styled.div`
    background: rgba(51, 170, 51, .1);
    height: 100%;
    width: 32px;
`;

const ListRow = ({
    data: {
        value,
        isClearing,
        moveMailToDeletedFolder,
        moveToFolderResult: { isLoading: isMovingMail },
        showDeleteIcon
    },
    index,
    style
}) => {
    const { mailId, setMailId } = usePersonalOutlookContext();
    const emailMessage = value[index];
    const loading = !emailMessage;

    const onClickEmail = (e) => {
        if (e && typeof (e.preventDefault)) {
            e.preventDefault();
        }

        setMailId(emailMessage.id);
    };

    const onDeleteEmail = (e) => {
        if (e && typeof (e.preventDefault)) {
            e.preventDefault();
        }

        toast.promise(moveMailToDeletedFolder(emailMessage.id), {
            loading: "Moving to deleted items...",
            error: (err) => err?.data?.message ?? err?.message ?? "Unable to delete mail.",
            success: "Moved to deleted items."
        });
    };

    if (loading || isClearing) {
        return <EmailLoadingPreview style={style} />;
    }

    return <EmailPreview
        key={emailMessage.id}
        style={style}
        className={classNames(
            'row',
            "p-0 py-1 m-0 border-bottom has-pointer",
            {
                active: mailId === emailMessage.id,
                'fw-bold': !emailMessage.isRead
            }
        )}
    >
        <div className="col text-truncate d-flex flex-column justify-content-center" onClick={onClickEmail}>
            <ThemedSpan className="text-truncate">{emailMessage.from?.emailAddress?.name ?? "<No sender>"}</ThemedSpan>
            <p className="m-0 text-truncate">{emailMessage.subject ?? "<No subject>"}</p>
            <p className="m-0 text-muted text-truncate">{emailMessage.preview}</p>
        </div>
        <div className="col-auto d-flex flex-column align-items-end justify-content-around">
            <Tooltip position={"left"} tooltip={emailMessage.isFiled ? "Filed" : "Not filed"}>
                <ThemedSpan variant={emailMessage.isFiled ? "success" : "danger"}>
                    <FontAwesomeIcon size="lg" icon={emailMessage.isFiled ? "file-circle-check" : "file-circle-xmark"} />
                </ThemedSpan>
            </Tooltip>
            {showDeleteIcon && <Tooltip position={"left"} tooltip={"Delete"}>
                <FontAwesomeIcon
                    size="lg"
                    className="text-muted me-1"
                    icon={"trash-can"}
                    onClick={isMovingMail ? () => { } : onDeleteEmail}
                />
            </Tooltip>}
        </div>

    </EmailPreview>;
};

const PersonalEmailMessages = () => {

    const [showDeleteIcon, setShowDeleteIcon] = useState(true);

    const {
        isClearing,
        isError,
        error,
        isLoading,
        totalCount,
        value,
        isItemLoaded,
        loadMoreItems,
        moveMailToDeletedFolder,
        moveToFolderResult
    } = useMessageSummaries();

    const { folders: { data: folders }, selectedFolderId } = usePersonalOutlookContext();

    useEffect(() => {
        if (!folders) return;

        var selectedFolder = folders.find(x => x.id === selectedFolderId);

        var selectedFolderName = selectedFolder.displayName.replace(" ", "").toLowerCase();
        setShowDeleteIcon(selectedFolderName !== "deleteditems");
    }, [selectedFolderId, folders]);

    if (isError) {
        return <ErrorMessageWrapper>
            <ErrorMessage error={error} />
        </ErrorMessageWrapper>
    }

    return <React.Fragment>
        <InfinityList
            isItemLoaded={isItemLoaded}
            itemCount={totalCount}
            isLoading={isLoading}
            itemData={{ value, isClearing, moveMailToDeletedFolder, moveToFolderResult, showDeleteIcon }}
            row={ListRow}
            loadMoreItems={loadMoreItems}
            itemSize={96}
        />
    </React.Fragment>
};

export default PersonalEmailMessages;