import { wealthProApi } from '../../redux/api';
import { useCacheKeyMergeHandler, useCacheKeyTransformResponseHandler, useCacheKeySerializeQueryArgsHandler } from "../../redux/helpers";

// TODO: add the bit that listens for messages and automatically appends the message!

const reportMessagesApiEndpoints = wealthProApi.injectEndpoints({
    endpoints: (builder) => ({
        fetchReportMessages: builder.query({
            query: ({ reportId, active = false, page = 1, limit = 100 }) => `/api/messaging/reports/${reportId}/messages?active=${active}&page=${page}&limit=${limit}`,
            transformResponse: useCacheKeyTransformResponseHandler,
            serializeQueryArgs: useCacheKeySerializeQueryArgsHandler,
            merge: useCacheKeyMergeHandler,
            // async onCacheEntryAdded({ reportId }, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
            //     try {
            //         await cacheDataLoaded;
            //         socket.on('on-message', (json) => {
            //             const msg = JSON.parse(json);
            //             updateCachedData((data) => {
            //                 if (data.results.findIndex(el => el.messageId === msg.messageId) < 0) {
            //                     data.results = [ msg, ...data.results ];
            //                     data.pagination.totalCount++;
            //                 }
            //             })
            //         });
            //     }
            //     catch {}
            //     await cacheEntryRemoved;
            //     socket.off('on-message');
            // }
        }),
    })
});

export const {
    useFetchReportMessagesQuery,
    useLazyFetchReportMessagesQuery
} = reportMessagesApiEndpoints;