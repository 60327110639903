import IconButton from "./IconButton";
import styled from "styled-components";
import { calculateButtonColour, calculateButtonText } from "../../helpers/theme";
import { darken } from "polished";
import { Button as ReactButton } from "react-bootstrap";

const Button = styled(ReactButton)`
    z-index: ${props => props.zindex ?? 0} !important;
    color: ${props => calculateButtonText(props)};
    background-color: ${props => calculateButtonColour(props)};
    border-color: ${props => calculateButtonColour(props)};

    &:hover,
    &:first-child:hover {
        color: ${props => calculateButtonText(props)};
        background-color: ${props => darken(0.05, calculateButtonColour(props))};
        border-color: ${props => darken(0.05, calculateButtonColour(props))};
    }

    &:active,
    &:first-child:active {
        color: ${props => calculateButtonText(props)};
        background-color: ${props => darken(0.05, calculateButtonColour(props))};
        border-color: ${props => darken(0.05, calculateButtonColour(props))};
    }

    &:focus,
    &:first-child:focus {
        color: ${props => calculateButtonText(props)};
        background-color: ${props => darken(0.05, calculateButtonColour(props))};
        border-color: ${props => darken(0.05, calculateButtonColour(props))};
        box-shadow: unset;
    }
`;

export const DropdownToggleButton = styled(Button)`
    position: relative;
    text-align: left;
    width: 100%;

    &:after {
        position: absolute;
        right: 15px;
        bottom: 45%;
    }
`;

export default Button;
export {
    IconButton
};