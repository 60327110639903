import React from "react";
import withProviders from "../../hooks/withProviders";
import PersonalReminders from "../../features/personalreminders";
import { Main, ThemedIcon, PageSubHeader, FiltersAppliedWarningMessage } from "../../components/utilities";
import PageHeader, { PageHeaderTop } from "../../components/layout/PageHeader";
import { PageHeaderTitle } from "../../components/utilities/PageHeader";
import { Button } from "../../components";
import PersonalRemindersProvider, { usePersonalReminders } from "../../features/personalreminders/hooks/PersonalRemindersContext";
import PersonalRemindersFilterModal from "../../features/personalreminders/components/PersonalRemindersFilterModal";
import { CreateReminderModal } from "../../features/createreminderform";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Spinner } from "../../components/loaders";

const PersonalRemindersPage = () => {
    const [isFiltersShown, setIsFiltersShown] = React.useState(false);
    const [isCreateShown, setIsCreateShown] = React.useState(false);
    const { isClearing, filter: { isFiltersApplied, clearFilter } } = usePersonalReminders();

    return <React.Fragment>
        <PersonalRemindersFilterModal
            show={isFiltersShown}
            handleClose={() => setIsFiltersShown(false)}
        />
        <CreateReminderModal
            show={isCreateShown}
            onHide={() => setIsCreateShown(false)}
            showUserSelect
        />
        <PageHeader>
            <PageHeaderTop>
                <PageHeaderTitle>
                    <span>Events, Appointments, Phone Calls and CPD</span>
                </PageHeaderTitle>
            </PageHeaderTop>
        </PageHeader>
        <Main className="d-flex flex-column">
            <PageSubHeader>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        {isFiltersApplied && (
                            <React.Fragment>
                                <ThemedIcon icon="fa-triangle-exclamation" size="2x" variant="warning" />
                                <FiltersAppliedWarningMessage className="ms-2 text-muted">
                                    Warning - Filters are currently being applied to your results.
                                    <br />
                                    <a href="#" onClick={clearFilter}>Click here</a> to clear your filters.
                                </FiltersAppliedWarningMessage>
                            </React.Fragment>
                        )}
                    </div>
                    <div className="d-flex flex-fill flex-row-reverse align-items-center">
                        <Button className="ms-3" onClick={() => setIsFiltersShown(true)}>
                            Filter Events
                        </Button>
                        <Button className="ms-3" variant="success" onClick={() => setIsCreateShown(true)}><FontAwesomeIcon icon="plus" /> Create New Event</Button>
                        {isClearing && <Spinner />}
                    </div>
                </div>
            </PageSubHeader>
            <section className="px-4 my-3 h-100">
                <PersonalReminders />
            </section>
        </Main>
    </React.Fragment>
};

export default withProviders(PersonalRemindersProvider)(PersonalRemindersPage);